import Grid from "@material-ui/core/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import "moment/locale/es";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import EditButton from "../../Utils/Botones/EditButton";
import NewButton from "../../Utils/Botones/NewButton";
import DataTable from "../../Utils/DataTables";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import Tabs from "../../Utils/Tabs";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewModal from "../../Utils/Visuales/NewModal";
import "./index.css";

function Asignaciones() {
  const headers = [
    {
      name: "Departamento",
      col: 0,
    },
    {
      name: "Grupo",
      col: 1,
    },
    {
      name: "Clave Materia",
      col: 2,
    },
    {
      name: "Nombre Materia",
      col: 3,
    },
    {
      name: "Acciones",
      col: 4,
    },
  ];

  const headersOnline = [
    {
      name: "Departamento",
      col: 0,
    },
    {
      name: "Grupo",
      col: 1,
    },
    {
      name: "Clave Materia",
      col: 2,
    },
    {
      name: "Nombre Materia",
      col: 3,
    },
    {
      name: "Módulo",
      col: 4,
    },
    {
      name: "Acciones",
      col: 5,
    },
  ];

  const headersTablaHorarioConsulta = [
    {
      name: "Lunes",
      col: 0,
    },
    {
      name: "Martes",
      col: 1,
    },
    {
      name: "Miércoles",
      col: 2,
    },
    {
      name: "Jueves",
      col: 3,
    },
    {
      name: "Viernes",
      col: 4,
    },
    {
      name: "Sábado",
      col: 5,
    },
    {
      name: "Domingo",
      col: 6,
    },
  ];

  const headersTablaHorarioAsignar = [
    {
      name: "Lunes",
      col: 0,
    },
    {
      name: "Martes",
      col: 1,
    },
    {
      name: "Miércoles",
      col: 2,
    },
    {
      name: "Jueves",
      col: 3,
    },
    {
      name: "Viernes",
      col: 4,
    },
    {
      name: "Sábado",
      col: 5,
    },
    {
      name: "Domingo",
      col: 6,
    },
  ];

  const headersTablaHorarioDesasignar = [
    {
      name: "Lunes",
      col: 0,
    },
    {
      name: "Martes",
      col: 1,
    },
    {
      name: "Miércoles",
      col: 2,
    },
    {
      name: "Jueves",
      col: 3,
    },
    {
      name: "Viernes",
      col: 4,
    },
    {
      name: "Sábado",
      col: 5,
    },
    {
      name: "Domingo",
      col: 6,
    },
  ];

  const headersTablaConsulta = [
    {
      field: "clave_profesor",
      headerName: "Clave Profesor",
      width: 125,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "nombre_profesor",
      headerName: "Nombre Profesor",
      width: 240,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "tipo_profesor",
      headerName: "Tipo de Profesor",
      width: 155,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "dia",
      headerName: "Día",
      width: 105,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "inicial",
      headerName: "Inicial",
      width: 70,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "final",
      headerName: "Final",
      width: 70,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "tipo_registro",
      headerName: "Tipo de Registro",
      width: 125,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "fecha_alta",
      headerName: "Fecha de Alta",
      width: 132,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "fecha_baja",
      headerName: "Fecha de Baja",
      width: 135,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
  ];

  const headersTableFechasMantenimiento = [
    {
      name: "Fecha de la Clase",
      col: 0,
    },
    {
      name: "Día al que pertenece",
      col: 1,
    },
    {
      name: "Inicial",
      col: 2,
    },
    {
      name: "Final",
      col: 3,
    },
  ];

  const tabsNamesConsulta = [[1, "Consulta"]];

  const [, setDataOriginal] = useState([]);
  const [dataFinal, setDataFinal] = useState([]);
  const [, setDataHorarioConsultaOriginal] = useState([]);
  const [, setDataHorarioAsignarOriginal] = useState([]);
  const [, setDataHorarioDesasignarOriginal] = useState([]);
  const [dataHorarioConsultaFinal, setDataHorarioConsultaFinal] = useState([]);
  const [dataHorarioAsignarFinal, setDataHorarioAsignarFinal] = useState([]);
  const [rule53, setRule53] = useState([]);
  const [dataHorarioDesasignarFinal, setDataHorarioDesasignarFinal] = useState(
    []
  );
  const { enqueueSnackbar } = useSnackbar();
  const [dataConsultaFinal, setDataConsultaFinal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHorarioConsulta] = useState(false);
  const [loadingHorarioAsignar] = useState(false);
  const [loadingHorarioDesasignar] = useState(false);
  const [consultaModalIsOpen, setConsultaModalIsOpen] = useState(false);
  const [asignarModalIsOpen, setAsignarModalIsOpen] = useState(false);
  const [desasignarModalIsOpen, setDesasignarModalIsOpen] = useState(false);
  const [loadingModalConsulta, setLoadingModalConsulta] = useState(false);
  const [loadingTablaConsulta, setLoadingTablaConsulta] = useState(false);
  const [loadingModalAsignar, setLoadingModalAsignar] = useState(false);
  const [loadingModalDesasignar, setLoadingModalDesasignar] = useState(false);
  const [horarioType, setHorarioType] = useState(1);
  const [showTable, setShowTable] = useState(false);
  const campusName = useSelector((state) => state.campusName);
  const permisos = useSelector((state) => state.permisos);
  const [cicloP, setCicloP] = useState("");
  const [profesorP, setProfesorP] = useState([]);
  const [porcentajeGSIP, setPorcentajeGSIP] = useState("");
  const [horasAsignadasP, setHorasAsignadasP] = useState("");
  const [horasGSIP, setHorasGSIP] = useState("");
  const [totalGruposP, setTotalGruposP] = useState("");
  const [escuelaP, setEscuelaP] = useState("");
  const [direccionP, setDireccionP] = useState("");
  const [consultarColor, setConsultarColor] = useState("");
  const [preassignmentDataTable, setPreassignmentDataTable] = useState([]);
  const [loadingPreassignmentTable, setLoadingPreassignmentTable] =
    useState(false);
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [optionsTipoGrupo, setOptionsTipoGrupo] = useState("");
  const [assignmentDetail, setAssignmentDetail] = useState({});
  const [profesorValue, setProfesorValue] = useState("");

  const [ciclo, setCiclo] = useState("");
  const [escuela, setEscuela] = useState("");
  const [tipoGrupo, setTipoGrupo] = useState("");
  const [direccion, setDireccion] = useState("");
  const [modulo, setModulo] = useState("");
  const [grupo, setGrupo] = useState("");
  const [materia, setMateria] = useState("");
  const [professorType, setProfessorType] = useState("");
  const [preasignacionModalIsOpen, setPreasignacionModalIsOpen] =
    useState(false);
  const campusId = useSelector((state) => state.campusId);
  const [fechaAlta, setFechaAlta] = useState("");
  const [datesValues, setDatesValues] = useState([]);
  const [daysValues, setDaysValues] = useState([]);
  const [horaIniciaValues, setHoraIniciaValues] = useState([]);
  const [minutosIniciaValues, setMinutosIniciaValues] = useState([]);
  const [horaTerminaValues, setHoraTerminaValues] = useState([]);
  const [minutosTerminaValues, setMinutosTerminaValues] = useState([]);
  const [nroFilas, setNroFilas] = useState([]);
  const [heightAsignar, setHeightAsignar] = useState(550);
  const [disabled, setDisabled] = useState(false);
  const [disabledRegistroAsistencia, setDisabledRegistroAsistencia] =
    useState(false);
  const [ocultarAsignacion, setOcultarAsignacion] = useState(false);
  const [professorTypeOptions, setProfessorTypeOptions] = useState("");
  const [idAssignmentColumn, setIdAssignmentColumn] = useState("");
  const [daysOptions, setDaysOptions] = useState("");
  const [datesOptions, setDatesOptions] = useState("");
  const [totalGrupos, setTotalGrupos] = useState("");
  const [horasAsignadas, setHorasAsignadas] = useState("");
  const [horasGsi, setHorasGsi] = useState("");
  const [porcentajeGsi, setPorcentajeGsi] = useState("");
  const [semaforoIDperson, setSemaforoIDperson] = useState("");
  const [semaforoIDassignment, setSemaforoIDassignment] = useState("");
  const [motivoBaja, setMotivoBaja] = useState("");
  const [optionsMotivoBaja, setOptionsMotivoBaja] = useState("");
  const day = new Date();
  const min_date = moment(day).format("YYYY-MM-DD");
  const [horasIniciales, setHorasIniciales] = useState([]);
  const [minutosIniciales, setMinutosIniciales] = useState([]);
  const [horasFinales, setHorasFinales] = useState([]);
  const [minutosFinales, setMinutosFinales] = useState([]);
  const [profesoresOptions, setProfesoresOptions] = useState([]);
  const [fechaBaja, setFechaBaja] = useState("");
  const [comentario, setComentario] = useState("");
  const [loadingModalComentario, setLoadingModalComentario] = useState(false);
  const [modalComentarioIsOpen, setModalComentarioIsOpen] = useState(false);
  const [loadingModalFechasMant, setLoadingModalFechasMant] = useState(false);
  const [modalFechasMantIsOpen, setModalFechasMantIsOpen] = useState(false);
  const [claveProfesorMant, setClaveProfesorMant] = useState("");
  const [dataFechasMantenimiento, setDataFechasMantenimiento] = useState([]);
  const [dataListadoDuplicados, setDataListadoDuplicados] = useState([]);
  const [campus, setCampus] = useState("");
  const [periodo, setPeriodo] = useState("");
  const campusOptions = useSelector((state) => state.campusUser);

  const showModalPreasignacion = () => {
    setPreasignacionModalIsOpen(true);
    setConsultarColor("");
  };

  async function handleClick() {
    if (ciclo === "") {
      enqueueSnackbar("Faltan llenar campo Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (grupo === "") {
      enqueueSnackbar("Faltan llenar campo Grupo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (campusId === 12 && campus === "") {
      enqueueSnackbar("Faltan Seleccionar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    let optionalParameters = "";
    if (escuela) {
      optionalParameters += `&id_escuela=${parseInt(escuela.value)}`;
    }
    if (direccion) {
      optionalParameters += `&id_direccion=${parseInt(direccion.value)}`;
    }
    if (tipoGrupo) {
      optionalParameters += `&tipo_grupo=${tipoGrupo.value}`;
    }
    if (modulo) {
      optionalParameters += `&modulo=${modulo}`;
    }
    if (materia) {
      optionalParameters += `&materia=${materia}`;
    }

    setShowTable(true);
    setLoading(true);
    try {
      let endpoint = "";
      if (campusId === 12) {
        endpoint =
          `/asignaciones?id_campus=${campus.value}&metodo=ONLN&ciclo=${ciclo}&grupo=${grupo}` +
          optionalParameters;
      } else {
        endpoint =
          `/asignaciones?id_campus=${campusId}&metodo=TR&ciclo=${ciclo}&grupo=${grupo}` +
          optionalParameters;
      }
      const response = await getRequest(endpoint);

      if (response.s === "OK") {
        if (response.d.length === 0) {
          enqueueSnackbar(
            "No se encontraron asignaciones que coincidan con la búsqueda",
            {
              variant: "info",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          setDataFinal([]);
        } else {
          const data = [];
          if (campusId === 12) {
            response.d.forEach((assignment) => {
              data.push([
                assignment.departamento,
                assignment.grupo,
                assignment.clave_materia,
                assignment.nombre_materia,
                assignment.modulo,
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <EditButton onClick={() => openModalConsulta(assignment.id)}>
                    Consultar
                  </EditButton>
                  <EditButton
                    onClick={() => openModalAsignar(assignment.id)}
                    style={{
                      display:
                        permisos["Asignaciones->Asignaciones"].escribir === "1"
                          ? "block"
                          : "none",
                    }}
                  >
                    Asignar
                  </EditButton>
                  <EditButton
                    onClick={() => openModalDesasignar(assignment.id)}
                    style={{
                      display:
                        permisos["Asignaciones->Asignaciones"].eliminar === "1"
                          ? "block"
                          : "none",
                    }}
                  >
                    Desasignar
                  </EditButton>
                </div>,
                assignment.id,
              ]);
            });
          } else {
            response.d.forEach((assignment) => {
              data.push([
                assignment.departamento,
                assignment.grupo,
                assignment.clave_materia,
                assignment.nombre_materia,
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <EditButton onClick={() => openModalConsulta(assignment.id)}>
                    Consultar
                  </EditButton>
                  <EditButton
                    onClick={() => openModalAsignar(assignment.id)}
                    style={{
                      display:
                        permisos["Asignaciones->Asignaciones"].escribir === "1"
                          ? "block"
                          : "none",
                    }}
                  >
                    Asignar
                  </EditButton>
                  <EditButton
                    onClick={() => openModalDesasignar(assignment.id)}
                    style={{
                      display:
                        permisos["Asignaciones->Asignaciones"].eliminar === "1"
                          ? "block"
                          : "none",
                    }}
                  >
                    Desasignar
                  </EditButton>
                </div>,
                assignment.id,
              ]);
            });
          }
          setDataOriginal(data);
          setDataFinal(data);
          await enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } else {
        await enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error al cargar asignaciones", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const openModalConsulta = async (id) => {
    setIdAssignmentColumn(id);
    setConsultaModalIsOpen(true);
    setLoadingModalConsulta(true);
    setConsultarColor("");

    const endpoint = `/asignaciones/detalle?id=${id}`;
    const assignmentDetailResponse = await getRequest(endpoint);
    let valido = true;
    if (
      assignmentDetailResponse.d === "" ||
      assignmentDetailResponse.d.length === 0
    ) {
      enqueueSnackbar("No se han encontrado datos", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setConsultaModalIsOpen(false);
      setLoadingModalConsulta(false);
      valido = false;
    }
    if (valido) {
      let scheduleData = "";
      if (campusId === 12) {
        scheduleData = [["", "", "", "", "", "", ""]];
      } else {
        scheduleData = [
          [
            assignmentDetailResponse.d.horario.lunes[0],
            assignmentDetailResponse.d.horario.martes[0],
            assignmentDetailResponse.d.horario.miercoles[0],
            assignmentDetailResponse.d.horario.jueves[0],
            assignmentDetailResponse.d.horario.viernes[0],
            assignmentDetailResponse.d.horario.sabado[0],
            assignmentDetailResponse.d.horario.domingo[0],
          ],
        ];
      }
      if (
        assignmentDetailResponse.d.tipo_asistencia === "NO PROCEDE" ||
        assignmentDetailResponse.d.tipo_asistencia === "No Procede"
      ) {
        enqueueSnackbar("El grupo es de tipo de asistencia 0", {
          variant: "info",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      let periodoFechas = `${assignmentDetailResponse.d.fecha_inicio.substring(
        8,
        10
      )}${assignmentDetailResponse.d.fecha_inicio.substring(
        4,
        8
      )}${assignmentDetailResponse.d.fecha_inicio.substring(
        0,
        4
      )} - ${assignmentDetailResponse.d.fecha_fin.substring(
        8,
        10
      )}${assignmentDetailResponse.d.fecha_fin.substring(
        4,
        8
      )}${assignmentDetailResponse.d.fecha_fin.substring(0, 4)}`;
      setPeriodo(periodoFechas);
      setDataHorarioConsultaOriginal(scheduleData);
      setDataHorarioConsultaFinal(scheduleData);
      setAssignmentDetail(assignmentDetailResponse.d);
      setLoadingModalConsulta(false);

      setLoadingTablaConsulta(true);
      const consulta = `/asignaciones/detalle-consulta?id=${id}`;
      let response = await getRequest(consulta);
      if (response.s === "OK") {
        let data = [];
        response.d.forEach((asignaciones) => {
          data.push({
            id: asignaciones.id_assignment_schedule,
            id_person: asignaciones.id_person,
            clave_profesor: asignaciones.clave_docente,
            nombre_profesor: asignaciones.nombre_docente,
            tipo_profesor: asignaciones.tipo_profesor,
            dia: campusId === 12 ? "" : asignaciones.dia,
            inicial:
              campusId === 12 ? "" : asignaciones.inicial.substring(0, 5),
            final: campusId === 12 ? "" : asignaciones.final.substring(0, 5),
            tipo_registro: asignaciones.tipo_registro,
            status: asignaciones.status,
            fecha_alta: asignaciones.fecha_alta_docente,
            fecha_baja: asignaciones.fecha_baja_docente,
            fechasMant: asignaciones.fecha_inicio,
          });
        });
        const valoresUnicos = {};
        const arrayFiltrados = [];
        const arrayListadoDuplicados = [];
        let nroFila = 0;
        for (let i = 0; i < data.length; i++) {
          const hash = `${data[i].clave_profesor}_${data[i].dia}_${data[i].inicial}_${data[i].final}_${data[i].fecha_alta}`;
          if (data[i].tipo_registro !== "fecha determinada") {
            data[i].nroFila = nroFila;
            arrayFiltrados.push({
              ...data[i],
              fecha_alta:
                data[i].fecha_alta === ""
                  ? ""
                  : moment(data[i].fecha_alta).format("DD-MM-YYYY"),
              fecha_baja:
                data[i].fecha_baja === ""
                  ? ""
                  : moment(data[i].fecha_baja).format("DD-MM-YYYY"),
            });
            nroFila++;
          } else if (!valoresUnicos[hash]) {
            data[i].nroFila = nroFila;
            arrayFiltrados.push({
              ...data[i],
              fecha_alta:
                data[i].fecha_alta === ""
                  ? ""
                  : moment(data[i].fecha_alta).format("DD-MM-YYYY"),
              fecha_baja:
                data[i].fecha_baja === ""
                  ? ""
                  : moment(data[i].fecha_baja).format("DD-MM-YYYY"),
            });
            valoresUnicos[hash] = true;
            nroFila++;
          } else {
            arrayListadoDuplicados.push({
              ...data[i],
              fecha_alta:
                data[i].fecha_alta === ""
                  ? ""
                  : moment(data[i].fecha_alta).format("DD-MM-YYYY"),
              fecha_baja:
                data[i].fecha_baja === ""
                  ? ""
                  : moment(data[i].fecha_baja).format("DD-MM-YYYY"),
            });
          }
        }
        setDataListadoDuplicados(arrayListadoDuplicados);
        setDataConsultaFinal(arrayFiltrados);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setLoadingTablaConsulta(false);
    }
  };

  const getRule53 = async () => {
    const request = `/parametro_reglas?id=53`;
    const response = await getRequest(request);
    if (response.s !== "OK") {
      enqueueSnackbar(response.m, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      setRule53(response?.d?.data?.clave_tipo_grupo);
    }
  };

  const openModalAsignar = async (id) => {
    setConsultarColor("");
    setIdAssignmentColumn(id);
    setAsignarModalIsOpen(true);
    setLoadingModalAsignar(true);

    const endpoint = `/asignaciones/detalle?id=${id}`;
    const assignmentDetailResponse = await getRequest(endpoint);
    let valido = true;
    if (
      assignmentDetailResponse.d === "" ||
      assignmentDetailResponse.d.length === 0
    ) {
      enqueueSnackbar(assignmentDetailResponse.m, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setAsignarModalIsOpen(false);
      setLoadingModalAsignar(false);
      valido = false;
    }
    if (valido) {
      let scheduleData = "";
      scheduleData = [
        [
          assignmentDetailResponse.d.horario.lunes[0],
          assignmentDetailResponse.d.horario.martes[0],
          assignmentDetailResponse.d.horario.miercoles[0],
          assignmentDetailResponse.d.horario.jueves[0],
          assignmentDetailResponse.d.horario.viernes[0],
          assignmentDetailResponse.d.horario.sabado[0],
          assignmentDetailResponse.d.horario.domingo[0],
        ],
      ];
      let dias = [];
      let horaInicia = [];
      let minutosInicia = [];
      let horaTermina = [];
      let minutosTermina = [];

      if (scheduleData[0][0]) {
        dias.push({ value: "lunes", label: "Lunes" });
        horaInicia.push(scheduleData[0][0].substring(0, 2));
        minutosInicia.push(scheduleData[0][0].substring(3, 5));
        horaTermina.push(scheduleData[0][0].substring(6, 8));
        minutosTermina.push(scheduleData[0][0].substring(9, 11));
      }
      if (scheduleData[0][1]) {
        dias.push({ value: "martes", label: "Martes" });
        horaInicia.push(scheduleData[0][1].substring(0, 2));
        minutosInicia.push(scheduleData[0][1].substring(3, 5));
        horaTermina.push(scheduleData[0][1].substring(6, 8));
        minutosTermina.push(scheduleData[0][1].substring(9, 11));
      }
      if (scheduleData[0][2]) {
        dias.push({ value: "miercoles", label: "Miércoles" });
        horaInicia.push(scheduleData[0][2].substring(0, 2));
        minutosInicia.push(scheduleData[0][2].substring(3, 5));
        horaTermina.push(scheduleData[0][2].substring(6, 8));
        minutosTermina.push(scheduleData[0][2].substring(9, 11));
      }
      if (scheduleData[0][3]) {
        dias.push({ value: "jueves", label: "Jueves" });
        horaInicia.push(scheduleData[0][3].substring(0, 2));
        minutosInicia.push(scheduleData[0][3].substring(3, 5));
        horaTermina.push(scheduleData[0][3].substring(6, 8));
        minutosTermina.push(scheduleData[0][3].substring(9, 11));
      }
      if (scheduleData[0][4]) {
        dias.push({ value: "viernes", label: "Viernes" });
        horaInicia.push(scheduleData[0][4].substring(0, 2));
        minutosInicia.push(scheduleData[0][4].substring(3, 5));
        horaTermina.push(scheduleData[0][4].substring(6, 8));
        minutosTermina.push(scheduleData[0][4].substring(9, 11));
      }
      if (scheduleData[0][5]) {
        dias.push({ value: "sabado", label: "Sábado" });
        horaInicia.push(scheduleData[0][5].substring(0, 2));
        minutosInicia.push(scheduleData[0][5].substring(3, 5));
        horaTermina.push(scheduleData[0][5].substring(6, 8));
        minutosTermina.push(scheduleData[0][5].substring(9, 11));
      }
      if (scheduleData[0][6]) {
        dias.push({ value: "domingo", label: "Domingo" });
        horaInicia.push(scheduleData[0][6].substring(0, 2));
        minutosInicia.push(scheduleData[0][6].substring(3, 5));
        horaTermina.push(scheduleData[0][6].substring(6, 8));
        minutosTermina.push(scheduleData[0][6].substring(9, 11));
      }

      setDaysOptions(dias);
      setHorasIniciales(horaInicia);
      setMinutosIniciales(minutosInicia);
      setHorasFinales(horaTermina);
      setMinutosFinales(minutosTermina);

      switch (dias.length) {
        case 0:
          setNroFilas([]);
          setDatesValues([]);
          setDaysValues([]);
          setHoraIniciaValues([]);
          setMinutosIniciaValues([]);
          setHoraTerminaValues([]);
          setMinutosTerminaValues([]);
          setHeightAsignar(550);
          break;
        case 1:
          setNroFilas([1]);
          setDatesValues([""]);
          setDaysValues([""]);
          setHoraIniciaValues([""]);
          setMinutosIniciaValues([""]);
          setHoraTerminaValues([""]);
          setMinutosTerminaValues([""]);
          setHeightAsignar(550);
          break;
        case 2:
          setNroFilas([1, 2]);
          setDatesValues(["", ""]);
          setDaysValues(["", ""]);
          setHoraIniciaValues(["", ""]);
          setMinutosIniciaValues(["", ""]);
          setHoraTerminaValues(["", ""]);
          setMinutosTerminaValues(["", ""]);
          setHeightAsignar(550);
          break;
        case 3:
          setNroFilas([1, 2, 3]);
          setDatesValues(["", "", ""]);
          setDaysValues(["", "", ""]);
          setHoraIniciaValues(["", "", ""]);
          setMinutosIniciaValues(["", "", ""]);
          setHoraTerminaValues(["", "", ""]);
          setMinutosTerminaValues(["", "", ""]);
          setHeightAsignar(550);
          break;
        case 4:
          setNroFilas([1, 2, 3, 4]);
          setDatesValues(["", "", "", ""]);
          setDaysValues(["", "", "", ""]);
          setHoraIniciaValues(["", "", "", ""]);
          setMinutosIniciaValues(["", "", "", ""]);
          setHoraTerminaValues(["", "", "", ""]);
          setMinutosTerminaValues(["", "", "", ""]);
          setHeightAsignar(595);
          break;
        case 5:
          setNroFilas([1, 2, 3, 4, 5]);
          setDatesValues(["", "", "", "", ""]);
          setDaysValues(["", "", "", "", ""]);
          setHoraIniciaValues(["", "", "", "", ""]);
          setMinutosIniciaValues(["", "", "", "", ""]);
          setHoraTerminaValues(["", "", "", "", ""]);
          setMinutosTerminaValues(["", "", "", "", ""]);
          setHeightAsignar(640);
          break;
        case 6:
          setNroFilas([1, 2, 3, 4, 5, 6]);
          setDatesValues(["", "", "", "", "", ""]);
          setDaysValues(["", "", "", "", "", ""]);
          setHoraIniciaValues(["", "", "", "", "", ""]);
          setMinutosIniciaValues(["", "", "", "", "", ""]);
          setHoraTerminaValues(["", "", "", "", "", ""]);
          setMinutosTerminaValues(["", "", "", "", "", ""]);
          setHeightAsignar(685);
          break;
        default:
          setNroFilas([1, 2, 3, 4, 5, 6, 7]);
          setDatesValues(["", "", "", "", "", "", ""]);
          setDaysValues(["", "", "", "", "", "", ""]);
          setHoraIniciaValues(["", "", "", "", "", "", ""]);
          setMinutosIniciaValues(["", "", "", "", "", "", ""]);
          setHoraTerminaValues(["", "", "", "", "", "", ""]);
          setMinutosTerminaValues(["", "", "", "", "", "", ""]);
          setHeightAsignar(730);
          break;
      }

      let fechas = [];
      for (
        let i = 0;
        i < assignmentDetailResponse.d.horario_fechas.length;
        i++
      ) {
        fechas.push({
          value: assignmentDetailResponse.d.horario_fechas[i],
          label: assignmentDetailResponse.d.horario_fechas[i],
        });
      }
      let comp = "";
      let finalDates = [];
      let nromin_date = parseInt(
        min_date.substring(0, 4) +
          min_date.substring(5, 7) +
          min_date.substring(8, 10)
      );
      fechas.forEach((item, i) => {
        comp = parseInt(
          fechas[i].value.substring(0, 4) +
            fechas[i].value.substring(5, 7) +
            fechas[i].value.substring(8, 10)
        );
        if (comp >= nromin_date) {
          finalDates.push(fechas[i]);
        }
      });

      if (
        assignmentDetailResponse.d.tipo_asistencia === "NO PROCEDE" ||
        assignmentDetailResponse.d.tipo_asistencia === "No Procede"
      ) {
        setDisabled(true);
        setDisabledRegistroAsistencia(true);
        enqueueSnackbar("El grupo es de tipo de asistencia 0", {
          variant: "info",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }

      if (assignmentDetailResponse.d.complementario === true) {
        setDisabled(true);
        setDisabledRegistroAsistencia(true);
        enqueueSnackbar("El grupo es complementario", {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }

      if (campusId === 12) {
        setDisabledRegistroAsistencia(true);
        setOcultarAsignacion(true);
        scheduleData = [["", "", "", "", "", "", ""]];
      }
      let periodoFechas = `${assignmentDetailResponse.d.fecha_inicio.substring(
        8,
        10
      )}${assignmentDetailResponse.d.fecha_inicio.substring(
        4,
        8
      )}${assignmentDetailResponse.d.fecha_inicio.substring(
        0,
        4
      )} - ${assignmentDetailResponse.d.fecha_fin.substring(
        8,
        10
      )}${assignmentDetailResponse.d.fecha_fin.substring(
        4,
        8
      )}${assignmentDetailResponse.d.fecha_fin.substring(0, 4)}`;

      setPeriodo(periodoFechas);
      setDatesOptions(finalDates);
      setDataHorarioAsignarOriginal(scheduleData);
      setDataHorarioAsignarFinal(scheduleData);
      setAssignmentDetail(assignmentDetailResponse.d);
      setLoadingModalAsignar(false);
    }
  };

  const openModalDesasignar = async (id) => {
    setIdAssignmentColumn(id);
    setDesasignarModalIsOpen(true);
    setLoadingModalDesasignar(true);
    consultarProfesores(id).then((data) => setProfesoresOptions(data));

    const endpoint = `/asignaciones/detalle?id=${id}`;
    const assignmentDetailResponse = await getRequest(endpoint);
    let valido = true;
    if (
      assignmentDetailResponse.d === "" ||
      assignmentDetailResponse.d.length === 0
    ) {
      enqueueSnackbar(assignmentDetailResponse.m, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setDesasignarModalIsOpen(false);
      setLoadingModalDesasignar(false);
      valido = false;
    }
    if (valido) {
      let scheduleData = "";
      if (campusId === 12) {
        scheduleData = [["", "", "", "", "", "", ""]];
      } else {
        scheduleData = [
          [
            assignmentDetailResponse.d.horario.lunes[0],
            assignmentDetailResponse.d.horario.martes[0],
            assignmentDetailResponse.d.horario.miercoles[0],
            assignmentDetailResponse.d.horario.jueves[0],
            assignmentDetailResponse.d.horario.viernes[0],
            assignmentDetailResponse.d.horario.sabado[0],
            assignmentDetailResponse.d.horario.domingo[0],
          ],
        ];
      }
      let dias = [];
      if (scheduleData[0][0]) dias.push({ value: "Lunes", label: "Lunes" });
      if (scheduleData[0][1]) dias.push({ value: "martes", label: "Martes" });
      if (scheduleData[0][2])
        dias.push({ value: "miercoles", label: "Miércoles" });
      if (scheduleData[0][3]) dias.push({ value: "jueves", label: "Jueves" });
      if (scheduleData[0][4]) dias.push({ value: "viernes", label: "Viernes" });
      if (scheduleData[0][5]) dias.push({ value: "sabado", label: "Sábado" });
      if (scheduleData[0][6]) dias.push({ value: "domingo", label: "Domingo" });
      setDaysOptions(dias);

      let fechas = [];
      for (
        let i = 0;
        i < assignmentDetailResponse.d.horario_fechas.length;
        i++
      ) {
        fechas.push({
          value: assignmentDetailResponse.d.horario_fechas[i],
          label: assignmentDetailResponse.d.horario_fechas[i],
        });
      }

      if (
        assignmentDetailResponse.d.tipo_asistencia === "NO PROCEDE" ||
        assignmentDetailResponse.d.tipo_asistencia === "No Procede"
      ) {
        setDisabled(true);
        setDisabledRegistroAsistencia(true);
        enqueueSnackbar("El grupo es de tipo de asistencia 0", {
          variant: "info",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }

      if (assignmentDetailResponse.d.complementario === true) {
        // setDisabled(true);
        enqueueSnackbar("El grupo es complementario", {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      let periodoFechas = `${assignmentDetailResponse.d.fecha_inicio.substring(
        8,
        10
      )}${assignmentDetailResponse.d.fecha_inicio.substring(
        4,
        8
      )}${assignmentDetailResponse.d.fecha_inicio.substring(
        0,
        4
      )} - ${assignmentDetailResponse.d.fecha_fin.substring(
        8,
        10
      )}${assignmentDetailResponse.d.fecha_fin.substring(
        4,
        8
      )}${assignmentDetailResponse.d.fecha_fin.substring(0, 4)}`;
      setPeriodo(periodoFechas);
      setDatesOptions(fechas);
      setDataHorarioDesasignarOriginal(scheduleData);
      setDataHorarioDesasignarFinal(scheduleData);
      setAssignmentDetail(assignmentDetailResponse.d);
      setLoadingModalDesasignar(false);
    }
  };

  const handleSemaforo = async (params) => {
    if (
      params.row.id === semaforoIDperson &&
      idAssignmentColumn === semaforoIDassignment
    ) {
      return;
    }
    try {
      const req = `/docentes/semaforo?id_person=${params.row.id_person}&id_assignment=${idAssignmentColumn}&id_campus=${campusId}&ciclo=${assignmentDetail.ciclo}&id_direccion=${assignmentDetail.id_direccion}`;
      const response = await getRequest(req);
      if (response.s === "OK") {
        setTotalGrupos(response.d.total_grupos);
        setHorasAsignadas(response.d.horas_asignadas);
        setConsultarColor(response.d.color);
        setHorasGsi(response.d.horas_GSI);
        setPorcentajeGsi(response.d.porcentaje_GSI.toFixed(2));
        setSemaforoIDperson(params.row.id_person);
        setSemaforoIDassignment(idAssignmentColumn);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const containersTabsConsulta = [
    [
      <DataGrid
        rows={dataConsultaFinal}
        columns={headersTablaConsulta}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        loading={loadingTablaConsulta}
        getRowHeight={() => "auto"}
        onRowClick={handleSemaforo}
        onCellClick={openMantenimientoFechas}
        sx={{
          fontSize: "18px",
        }}
      />,
    ],
  ];

  const tabsNamesAsignar = [
    [1, "Consulta"],
    // [2, "Asignacion Periodo"],
  ];

  const limpiar = () => {
    setDisabled(false);
    setDisabledRegistroAsistencia(false);
    switch (daysOptions.length) {
      case 0:
        setNroFilas([]);
        setDatesValues([]);
        setDaysValues([]);
        setHoraIniciaValues([]);
        setMinutosIniciaValues([]);
        setHoraTerminaValues([]);
        setMinutosTerminaValues([]);
        setHeightAsignar(550);
        break;
      case 1:
        setNroFilas([1]);
        setDatesValues([""]);
        setDaysValues([""]);
        setHoraIniciaValues([""]);
        setMinutosIniciaValues([""]);
        setHoraTerminaValues([""]);
        setMinutosTerminaValues([""]);
        setHeightAsignar(550);
        break;
      case 2:
        setNroFilas([1, 2]);
        setDatesValues(["", ""]);
        setDaysValues(["", ""]);
        setHoraIniciaValues(["", ""]);
        setMinutosIniciaValues(["", ""]);
        setHoraTerminaValues(["", ""]);
        setMinutosTerminaValues(["", ""]);
        setHeightAsignar(550);
        break;
      case 3:
        setNroFilas([1, 2, 3]);
        setDatesValues(["", "", ""]);
        setDaysValues(["", "", ""]);
        setHoraIniciaValues(["", "", ""]);
        setMinutosIniciaValues(["", "", ""]);
        setHoraTerminaValues(["", "", ""]);
        setMinutosTerminaValues(["", "", ""]);
        setHeightAsignar(550);
        break;
      case 4:
        setNroFilas([1, 2, 3, 4]);
        setDatesValues(["", "", "", ""]);
        setDaysValues(["", "", "", ""]);
        setHoraIniciaValues(["", "", "", ""]);
        setMinutosIniciaValues(["", "", "", ""]);
        setHoraTerminaValues(["", "", "", ""]);
        setMinutosTerminaValues(["", "", "", ""]);
        setHeightAsignar(595);
        break;
      case 5:
        setNroFilas([1, 2, 3, 4, 5]);
        setDatesValues(["", "", "", "", ""]);
        setDaysValues(["", "", "", "", ""]);
        setHoraIniciaValues(["", "", "", "", ""]);
        setMinutosIniciaValues(["", "", "", "", ""]);
        setHoraTerminaValues(["", "", "", "", ""]);
        setMinutosTerminaValues(["", "", "", "", ""]);
        setHeightAsignar(640);
        break;
      case 6:
        setNroFilas([1, 2, 3, 4, 5, 6]);
        setDatesValues(["", "", "", "", "", ""]);
        setDaysValues(["", "", "", "", "", ""]);
        setHoraIniciaValues(["", "", "", "", "", ""]);
        setMinutosIniciaValues(["", "", "", "", "", ""]);
        setHoraTerminaValues(["", "", "", "", "", ""]);
        setMinutosTerminaValues(["", "", "", "", "", ""]);
        setHeightAsignar(685);
        break;
      default:
        setNroFilas([1, 2, 3, 4, 5, 6, 7]);
        setDatesValues(["", "", "", "", "", "", ""]);
        setDaysValues(["", "", "", "", "", "", ""]);
        setHoraIniciaValues(["", "", "", "", "", "", ""]);
        setMinutosIniciaValues(["", "", "", "", "", "", ""]);
        setHoraTerminaValues(["", "", "", "", "", "", ""]);
        setMinutosTerminaValues(["", "", "", "", "", "", ""]);
        setHeightAsignar(730);
        break;
    }
  };

  function handleChangeTipo(ev) {
    if (ev.target.value === "determinada") {
      setHorarioType(2);
      setearFechasTitulares();
    } else {
      setHorarioType(1);
      setearDiasTitulares();
    }
  }

  const minChars = 5;
  const waitTime = 2000;
  let timer;

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };
  //185px
  const asyncRowSelectStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: "100px",
    }),
  };
  const selectsAsignarStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: "105px",
    }),
  };

  async function openMantenimientoFechas(params) {
    if (
      params.colDef.field === "dia" &&
      params.row.tipo_registro === "fecha determinada"
    ) {
      setModalFechasMantIsOpen(true);
      setLoadingModalFechasMant(true);
      setClaveProfesorMant(
        `${dataConsultaFinal[params.row.nroFila].clave_profesor} - ${
          dataConsultaFinal[params.row.nroFila].nombre_profesor
        }`
      );
      const fechas = [];
      dataListadoDuplicados.forEach((item) => {
        if (item.id_person === dataConsultaFinal[params.row.nroFila].id_person)
          fechas.push(item);
      });
      // console.log(fechas);
      var fecha = dataConsultaFinal[params.row.nroFila].fechasMant;
      fecha = `${fecha.substring(8, 10)}${fecha.substring(
        4,
        8
      )}${fecha.substring(0, 4)}`;
      // console.log(fecha);
      const dataTable = [
        [
          fecha,
          dataConsultaFinal[params.row.nroFila].dia,
          dataConsultaFinal[params.row.nroFila].inicial,
          dataConsultaFinal[params.row.nroFila].final,
        ],
      ];
      let mismosProfesores = [];
      dataConsultaFinal.forEach((item) => {
        if (
          item.clave_profesor ===
            dataConsultaFinal[params.row.nroFila].clave_profesor &&
          item.nroFila !== params.row.nroFila
        )
          mismosProfesores.push(item.nroFila);
      });
      if (mismosProfesores.length !== 0) {
        mismosProfesores.forEach((nro) => {
          fecha = dataConsultaFinal[nro].fechasMant;
          fecha = `${fecha.substring(8, 10)}${fecha.substring(
            4,
            8
          )}${fecha.substring(0, 4)}`;
          // console.log(fecha);
          dataTable.push([
            fecha,
            dataConsultaFinal[nro].dia,
            dataConsultaFinal[nro].inicial,
            dataConsultaFinal[nro].final,
          ]);
        });
      }
      if (fechas.length !== 0) {
        fechas.forEach((item) => {
          fecha = `${item.fechasMant.substring(
            8,
            10
          )}${item.fechasMant.substring(4, 8)}${item.fechasMant.substring(
            0,
            4
          )}`;
          // console.log(fecha);
          dataTable.push([fecha, item.dia, item.inicial, item.final]);
        });
      }
      setDataFechasMantenimiento(
        dataTable.filter((element) => element[0] !== "01-01-1900")
      );
      setLoadingModalFechasMant(false);
    }
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  async function searchProfesor(valor) {
    try {
      const options = [];
      const urlAll = `/buscar?tipo=profesor&valor=${valor}`;
      const urlActive = `/buscar?tipo=profesor_activos&valor=${valor}`;
      const consulta = rule53.includes(assignmentDetail.clave_tipo_grupo)
        ? urlActive
        : urlAll;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
            name: item.nombre,
            nss: item.nss,
            valido: item.valido,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const setearFechasTitulares = () => {
    const filas = [];
    const dates = [];
    const hor_iniciales = [];
    const min_iniciales = [];
    const hor_finales = [];
    const min_finales = [];
    let height = 550;
    datesOptions.forEach((item, i) => {
      filas.push(i + 1);
      dates.push(datesOptions[i]);
      if (i > 2) height += 45;
    });
    if (horasIniciales.length === 1) {
      for (let i = 0; i < dates.length; i++) {
        hor_iniciales.push(horasIniciales[0]);
        min_iniciales.push(minutosIniciales[0]);
        hor_finales.push(horasFinales[0]);
        min_finales.push(minutosFinales[0]);
      }
    } else {
      dates.forEach((item) => {
        const momentDay = moment(item.value).format("dddd").toLocaleLowerCase();
        const index = daysOptions.findIndex(
          (item) => item.label.toLocaleLowerCase() === momentDay
        );
        hor_iniciales.push(horasIniciales[index]);
        min_iniciales.push(minutosIniciales[index]);
        hor_finales.push(horasFinales[index]);
        min_finales.push(minutosFinales[index]);
      });
    }
    setHoraIniciaValues(hor_iniciales);
    setMinutosIniciaValues(min_iniciales);
    setHoraTerminaValues(hor_finales);
    setMinutosTerminaValues(min_finales);
    setDatesValues(dates);
    setHeightAsignar(height);
    setNroFilas(filas);
  };

  const setearDiasTitulares = () => {
    switch (daysOptions.length) {
      case 1:
        setNroFilas([1]);
        setDaysValues([daysOptions[0]]);
        setHoraIniciaValues([horasIniciales[0]]);
        setMinutosIniciaValues([minutosIniciales[0]]);
        setHoraTerminaValues([horasFinales[0]]);
        setMinutosTerminaValues([minutosFinales[0]]);
        setHeightAsignar(550);
        break;
      case 2:
        setNroFilas([1, 2]);
        setHeightAsignar(550);
        setDaysValues([daysOptions[0], daysOptions[1]]);
        setHoraIniciaValues([horasIniciales[0], horasIniciales[1]]);
        setMinutosIniciaValues([minutosIniciales[0], minutosIniciales[1]]);
        setHoraTerminaValues([horasFinales[0], horasFinales[1]]);
        setMinutosTerminaValues([minutosFinales[0], minutosFinales[1]]);
        break;
      case 3:
        setNroFilas([1, 2, 3]);
        setHeightAsignar(550);
        setDaysValues([daysOptions[0], daysOptions[1], daysOptions[2]]);
        setHoraIniciaValues([
          horasIniciales[0],
          horasIniciales[1],
          horasIniciales[2],
        ]);
        setMinutosIniciaValues([
          minutosIniciales[0],
          minutosIniciales[1],
          minutosIniciales[2],
        ]);
        setHoraTerminaValues([
          horasFinales[0],
          horasFinales[1],
          horasFinales[2],
        ]);
        setMinutosTerminaValues([
          minutosFinales[0],
          minutosFinales[1],
          minutosFinales[2],
        ]);
        break;
      case 4:
        setNroFilas([1, 2, 3, 4]);
        setHeightAsignar(595);
        setDaysValues([
          daysOptions[0],
          daysOptions[1],
          daysOptions[2],
          daysOptions[3],
        ]);
        setHoraIniciaValues([
          horasIniciales[0],
          horasIniciales[1],
          horasIniciales[2],
          horasIniciales[3],
        ]);
        setMinutosIniciaValues([
          minutosIniciales[0],
          minutosIniciales[1],
          minutosIniciales[2],
          minutosIniciales[3],
        ]);
        setHoraTerminaValues([
          horasFinales[0],
          horasFinales[1],
          horasFinales[2],
          horasFinales[3],
        ]);
        setMinutosTerminaValues([
          minutosFinales[0],
          minutosFinales[1],
          minutosFinales[2],
          minutosFinales[3],
        ]);
        break;
      case 5:
        setNroFilas([1, 2, 3, 4, 5]);
        setHeightAsignar(640);
        setDaysValues([
          daysOptions[0],
          daysOptions[1],
          daysOptions[2],
          daysOptions[3],
          daysOptions[4],
        ]);
        setHoraIniciaValues([
          horasIniciales[0],
          horasIniciales[1],
          horasIniciales[2],
          horasIniciales[3],
          horasIniciales[4],
        ]);
        setMinutosIniciaValues([
          minutosIniciales[0],
          minutosIniciales[1],
          minutosIniciales[2],
          minutosIniciales[3],
          minutosIniciales[4],
        ]);
        setHoraTerminaValues([
          horasFinales[0],
          horasFinales[1],
          horasFinales[2],
          horasFinales[3],
          horasFinales[4],
        ]);
        setMinutosTerminaValues([
          minutosFinales[0],
          minutosFinales[1],
          minutosFinales[2],
          minutosFinales[3],
          minutosFinales[4],
        ]);
        break;
      case 6:
        setNroFilas([1, 2, 3, 4, 5, 6]);
        setHeightAsignar(685);
        setDaysValues([
          daysOptions[0],
          daysOptions[1],
          daysOptions[2],
          daysOptions[3],
          daysOptions[4],
          daysOptions[5],
        ]);
        setHoraIniciaValues([
          horasIniciales[0],
          horasIniciales[1],
          horasIniciales[2],
          horasIniciales[3],
          horasIniciales[4],
          horasIniciales[5],
        ]);
        setMinutosIniciaValues([
          minutosIniciales[0],
          minutosIniciales[1],
          minutosIniciales[2],
          minutosIniciales[3],
          minutosIniciales[4],
          minutosIniciales[5],
        ]);
        setHoraTerminaValues([
          horasFinales[0],
          horasFinales[1],
          horasFinales[2],
          horasFinales[3],
          horasFinales[4],
          horasFinales[5],
        ]);
        setMinutosTerminaValues([
          minutosFinales[0],
          minutosFinales[1],
          minutosFinales[2],
          minutosFinales[3],
          minutosFinales[4],
          minutosFinales[5],
        ]);
        break;
      default:
        setNroFilas([1, 2, 3, 4, 5, 6, 7]);
        setHeightAsignar(730);
        setDaysValues([
          daysOptions[0],
          daysOptions[1],
          daysOptions[2],
          daysOptions[3],
          daysOptions[4],
          daysOptions[5],
          daysOptions[6],
        ]);
        setHoraIniciaValues([
          horasIniciales[0],
          horasIniciales[1],
          horasIniciales[2],
          horasIniciales[3],
          horasIniciales[4],
          horasIniciales[5],
          horasIniciales[6],
        ]);
        setMinutosIniciaValues([
          minutosIniciales[0],
          minutosIniciales[1],
          minutosIniciales[2],
          minutosIniciales[3],
          minutosIniciales[4],
          minutosIniciales[5],
          minutosIniciales[6],
        ]);
        setHoraTerminaValues([
          horasFinales[0],
          horasFinales[1],
          horasFinales[2],
          horasFinales[3],
          horasFinales[4],
          horasFinales[5],
          horasFinales[6],
        ]);
        setMinutosTerminaValues([
          minutosFinales[0],
          minutosFinales[1],
          minutosFinales[2],
          minutosFinales[3],
          minutosFinales[4],
          minutosFinales[5],
          minutosFinales[6],
        ]);
        break;
    }
  };

  async function handleProfessorType(e) {
    setProfessorType(e);
    if (horarioType === 2) {
      setearFechasTitulares();
      if (profesorValue) {
        const fechaAltaSelected = datesOptions[0]?.value;
        setFechaAlta(
          assignmentDetail?.metodo === "ONLN"
            ? moment(assignmentDetail?.fecha_inicio).isAfter(moment())
              ? assignmentDetail?.fecha_inicio
              : moment().format("YYYY-MM-DD")
            : fechaAltaSelected
        );
      }
    } else {
      setearDiasTitulares();
      if (profesorValue) {
        const fechaAltaSelected = datesOptions[0]?.value;
        setFechaAlta(
          assignmentDetail?.metodo === "ONLN"
            ? moment(assignmentDetail?.fecha_inicio).isAfter(moment())
              ? assignmentDetail?.fecha_inicio
              : moment().format("YYYY-MM-DD")
            : fechaAltaSelected
        );
      }
    }
  }

  async function handleProfesorSemaforo(e) {
    setProfesorValue(e);
    let getDay = moment().format("YYYY-MM-DD");
    let getNroDay = new Date(getDay).getDay();
    if (getNroDay === 6) {
      setFechaBaja(new Date(getDay.getMilliseconds() + 86400000));
    } else {
      setFechaBaja(getDay);
    }
    if (e.id_person === semaforoIDperson && e.value === semaforoIDassignment) {
      return;
    }
    try {
      const req = `/docentes/semaforo?id_person=${e.id_person}&id_assignment=${e.value}&id_campus=${campusId}&ciclo=${assignmentDetail.ciclo}&id_direccion=${assignmentDetail.id_direccion}`;
      const response = await getRequest(req);
      if (response.s === "OK") {
        setTotalGrupos(response.d.total_grupos);
        setHorasAsignadas(response.d.horas_asignadas);
        setConsultarColor(response.d.color);
        setHorasGsi(response.d.horas_GSI);
        setPorcentajeGsi(response.d.porcentaje_GSI.toFixed(2));
        setSemaforoIDperson(e.id_person);
        setSemaforoIDassignment(e.value);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleProfesorSemaforoAsignar(e) {
    if (e.valido === "ERROR") {
      enqueueSnackbar("El Profesor no esta Activo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    setProfesorValue(e);
    if (professorType !== "") {
      const fechaAltaSelected = datesOptions[0].value;
      setFechaAlta(fechaAltaSelected);
    }
    if (
      e.value === semaforoIDperson &&
      idAssignmentColumn === semaforoIDassignment
    ) {
      return;
    }
    try {
      const req = `/docentes/semaforo?id_person=${e.value}&id_assignment=${idAssignmentColumn}&id_campus=${campusId}&ciclo=${assignmentDetail.ciclo}&id_direccion=${assignmentDetail.id_direccion}`;
      const response = await getRequest(req);
      if (response.s === "OK") {
        setTotalGrupos(response.d.total_grupos);
        setHorasAsignadas(response.d.horas_asignadas);
        setConsultarColor(response.d.color);
        setHorasGsi(response.d.horas_GSI);
        setPorcentajeGsi(response.d.porcentaje_GSI.toFixed(2));
        setSemaforoIDperson(e.value);
        setSemaforoIDassignment(idAssignmentColumn);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDatesValues = (e, i) => {
    let values = [...datesValues];
    values[i] = e;
    setDatesValues(values);
  };

  const handleDaysValues = (e, i) => {
    let values = [...daysValues];
    values[i] = e;
    setDaysValues(values);
  };

  function handleHoraValue(value, i, type) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      if (type === "i") {
        let values = [...horaIniciaValues];
        values[i] = value;
        setHoraIniciaValues(values);
      } else {
        let values = [...horaTerminaValues];
        values[i] = value;
        setHoraTerminaValues(values);
      }
    }
  }

  function handleMinutosValue(value, i, type) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      if (type === "i") {
        let values = [...minutosIniciaValues];
        values[i] = value;
        setMinutosIniciaValues(values);
      } else {
        let values = [...minutosTerminaValues];
        values[i] = value;
        setMinutosTerminaValues(values);
      }
    }
  }
  const containersTabsAsignar = [
    [
      <div>
        <Grid container spacing={3} className="consult-tab">
          <Grid item xs={6} className="consult-tab-option">
            <label>Clave Profesor:</label>
            <AsyncSelect
              className="select-professor"
              classNamePrefix="select-modal"
              isDisabled={disabled}
              styles={asyncSelectStyles}
              loadOptions={promiseOptions}
              value={profesorValue}
              onChange={(e) => handleProfesorSemaforoAsignar(e)}
            />
          </Grid>
          <Grid item xs={6} className="consult-tab-option">
            <label>Tipo de Profesor:</label>
            <Select
              className="preassignment-filters_option-select"
              classNamePrefix="select-modal"
              isDisabled={disabled}
              options={professorTypeOptions}
              value={professorType}
              onChange={(e) => handleProfessorType(e)}
              checked={horarioType === 2}
            />
          </Grid>

          <Grid item xs={6} className="consult-tab-option">
            <label>Fecha de Alta:</label>
            <input
              className="filter-input"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled}
              min={min_date}
              value={fechaAlta}
              onChange={(e) => setFechaAlta(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} className="consult-tab-option">
            <label>Tipo de Registro de Asistencia:</label>
            <form className="radio-form">
              <fieldset>
                <div>
                  <div onChange={handleChangeTipo}>
                    <div>
                      <input
                        type="radio"
                        id="fijo"
                        name="tipo-registro"
                        value="fijo"
                        disabled={disabledRegistroAsistencia}
                        checked={horarioType === 1}
                      />
                      <label for="fijo">Periodo fijo</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="determinada"
                        name="tipo-registro"
                        value="determinada"
                        disabled={disabledRegistroAsistencia}
                        checked={horarioType === 2}
                      />
                      <label for="determinada">Fecha determinada</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </Grid>
          <Grid
            item
            xs={12}
            className="consult-tab-option_center"
            style={{ display: ocultarAsignacion ? "none" : "block" }}
          >
            {horarioType === 2 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-1%",
                }}
              >
                <label style={{ marginLeft: "37%" }}>
                  Asignación de Horario
                </label>
                <EditButton
                  style={{ marginLeft: "35%" }}
                  disabled={disabled}
                  onClick={() => agregarNuevaFila(nroFilas)}
                >
                  Agregar
                </EditButton>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-1%",
                }}
              >
                <label>Asignación de Horario</label>
              </div>
            )}
            <div>
              <table
                className="input-table"
                style={{ width: "78%", marginTop: "1%" }}
              >
                <tr>
                  {horarioType === 2 && <th>Fecha</th>}
                  {horarioType === 1 && <th>Día</th>}
                  <th>Inicia</th>
                  <th>Termina</th>
                </tr>
                {nroFilas.map((e, i) => (
                  <tr>
                    {horarioType === 2 && (
                      <td>
                        <Select
                          styles={selectsAsignarStyles}
                          options={datesOptions.filter(
                            (daysOptions) =>
                              !datesValues.some(
                                (day) => day?.label === daysOptions?.label
                              )
                          )}
                          value={datesValues[i]}
                          isDisabled={disabled}
                          onChange={(e) => handleDatesValues(e, i)}
                        />
                      </td>
                    )}
                    {horarioType === 1 && (
                      <td>
                        <Select
                          styles={selectsAsignarStyles}
                          options={daysOptions.filter(
                            (daysOptions) =>
                              !daysValues.some(
                                (day) => day?.label === daysOptions?.label
                              )
                          )}
                          value={daysValues[i]}
                          isDisabled={disabled}
                          onChange={(e) => handleDaysValues(e, i)}
                        />
                      </td>
                    )}
                    <td>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <input
                          className="filter-input"
                          type="number"
                          style={{ width: "30%" }}
                          disabled={disabled}
                          min="0"
                          max="23"
                          // placeholder="23"
                          value={horaIniciaValues[i]}
                          onChange={(e) =>
                            handleHoraValue(e.target.value, i, "i")
                          }
                        />
                        <input
                          className="filter-input"
                          style={{ marginLeft: "1%", width: "30%" }}
                          type="number"
                          disabled={disabled}
                          min="0"
                          max="59"
                          // placeholder="00"
                          value={minutosIniciaValues[i]}
                          onChange={(e) =>
                            handleMinutosValue(e.target.value, i, "i")
                          }
                        />
                      </div>
                      {/* <input
                        type="time"
                        className="filter-input"
                        style={{ width: "50%" }}
                        value={iniciaValues[i]}
                        disabled={disabled}
                        onChange={(e) => handleIniciaValues(e, i)}
                      /> */}
                    </td>
                    <td>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <input
                          className="filter-input"
                          type="number"
                          style={{ width: "30%" }}
                          disabled={disabled}
                          min="0"
                          max="23"
                          // placeholder="23"
                          value={horaTerminaValues[i]}
                          onChange={(e) =>
                            handleHoraValue(e.target.value, i, "t")
                          }
                        />
                        <input
                          className="filter-input"
                          style={{ marginLeft: "1%", width: "30%" }}
                          type="number"
                          disabled={disabled}
                          min="0"
                          max="59"
                          // placeholder="00"
                          value={minutosTerminaValues[i]}
                          onChange={(e) =>
                            handleMinutosValue(e.target.value, i, "t")
                          }
                        />
                      </div>
                      {/* <input
                        type="time"
                        className="filter-input"
                        style={{ width: "50%" }}
                        value={terminaValues[i]}
                        disabled={disabled}
                        onChange={(e) => handleTerminaValues(e, i)}
                      /> */}
                    </td>
                    <td>
                      <div
                        className="table-delete"
                        style={{ marginLeft: "38.5%", zIndex: 99 }}
                        onClick={() => deleteRow(i)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </Grid>
        </Grid>
      </div>,
    ],
    [<div>Pagina 2</div>],
  ];

  const tabsNamesDesasignar = [
    [1, "Consulta"],
    //[2, "Asignacion Periodo"],
  ];

  const containersTabsDesasignar = [
    [
      <div>
        <Grid container style={{ alignItems: "center" }}>
          <Grid item xs={2} style={{ marginTop: "20px", marginRight: "10px" }}>
            <label style={{ float: "right" }}>Clave Profesor:</label>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "20px" }}>
            <Select
              className="select-professor"
              classNamePrefix="select-modal"
              isDisabled={disabled}
              options={profesoresOptions}
              value={profesorValue}
              onChange={(e) => handleProfesorSemaforo(e)}
            />
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={2} style={{ marginTop: "20px", marginRight: "10px" }}>
            <label style={{ float: "right" }}>Motivo de Baja:</label>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "20px" }}>
            <Select
              classNamePrefix="select-modal"
              isDisabled={disabled}
              options={optionsMotivoBaja}
              value={motivoBaja}
              onChange={setMotivoBaja}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={2} style={{ marginTop: "20px", marginRight: "10px" }}>
            <label style={{ float: "right" }}>Fecha de Baja:</label>
          </Grid>
          <Grid item xs={2} style={{ marginTop: "20px" }}>
            <input
              className="filter-input"
              label="Seleccionar fecha"
              type="date"
              disabled={disabled}
              // min={min_date}
              value={fechaBaja}
              onChange={(e) => setFechaBaja(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "150%", fontSize: "16px" }}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={2} style={{ marginTop: "20px", marginRight: "10px" }}>
            <label style={{ float: "right" }}>Comentario:</label>
          </Grid>
          <Grid item xs={9} style={{ marginTop: "20px" }}>
            <TextareaAutosize
              className="filter-input value-comentario"
              maxRows={7}
              style={{
                width: "100%",
                height: 120,
                resize: "none",
                overflowY: "scroll",
                fontSize: "16px",
              }}
              disabled={disabled}
              value={comentario}
              onChange={(e) => setComentario(e.target.value)}
            />
          </Grid>
        </Grid>
      </div>,
    ],
    //[<div>Pagina 2</div>],
  ];

  // Preassignment dummy data
  var columns = [
    {
      field: "grupo",
      headerName: "Grupo",
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "materia",
      headerName: "Materia",
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "descripcion",
      headerName: "Descripción",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      disableColumnMenu: true,
    },
    {
      field: "lunes",
      headerName: "Lunes",
      minWidth: 60,
      headerAlign: "center",
      align: "center",
      fontSize: "1px",
      disableColumnMenu: true,
    },
    {
      field: "martes",
      headerName: "Martes",
      headerAlign: "center",
      align: "center",
      minWidth: 66,
      disableColumnMenu: true,
    },
    {
      field: "miercoles",
      headerName: "Miércoles",
      headerAlign: "center",
      align: "center",
      minWidth: 84,
      disableColumnMenu: true,
    },
    {
      field: "jueves",
      headerName: "Jueves",
      headerAlign: "center",
      align: "center",
      minWidth: 65,
      disableColumnMenu: true,
    },
    {
      field: "viernes",
      headerName: "Viernes",
      headerAlign: "center",
      align: "center",
      minWidth: 68,
      disableColumnMenu: true,
    },
    {
      field: "sabado",
      headerName: "Sábado",
      headerAlign: "center",
      align: "center",
      minWidth: 69,
      disableColumnMenu: true,
    },
    {
      field: "domingo",
      headerName: "Domingo",
      headerAlign: "center",
      align: "center",
      minWidth: 78,
      disableColumnMenu: true,
    },
    {
      field: "profPreasig",
      headerName: "Prof. PreAsig",
      headerAlign: "center",
      align: "center",
      minWidth: 200,
      renderCell: (params) => (
        <AsyncSelect
          className="select-professor"
          maxMenuHeight={3000}
          classNamePrefix="select-modal"
          styles={asyncRowSelectStyles}
          loadOptions={promiseOptions}
          isDisabled={
            preassignmentDataTable[params.id]["id_profesor"] === ""
              ? false
              : true
          }
          value={profesorP[params.id]}
          onChange={(event) => handleProfesorP(event, params.id)}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      ),
    },
    {
      field: "A",
      minWidth: 10,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <button
          className="table-arrow"
          onClick={() => handleAssign(params.id)}
          disabled={loadingPreassignmentTable}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      ),
      disableColumnMenu: true,
    },
    {
      field: "profDefinitivo",
      headerName: "Prof Definitivo",
      headerAlign: "center",
      align: "center",
      minWidth: 111,
      disableColumnMenu: true,
    },
    {
      field: "nombre_profesor",
      headerName: "Nombre",
      headerAlign: "center",
      align: "center",
      minWidth: 160,
      disableColumnMenu: true,
    },
    {
      field: "fechaAlta",
      headerName: "Fecha Alta",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: "asig",
      headerName: "Asig",
      headerAlign: "center",
      align: "center",
      minWidth: 50,
      disableColumnMenu: true,
    },
    {
      field: "D",
      minWidth: 30,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div
          className="table-delete"
          style={{
            display:
              preassignmentDataTable[params.id]["id_profesor"] === ""
                ? "none"
                : "",
          }}
          onClick={() => handleDeleteAssign(params.id)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
      ),
      disableColumnMenu: true,
    },
  ];

  const handleProfesorP = async (event, idRow) => {
    let profesor = [...profesorP];
    profesor[idRow] = {
      idRow: idRow,
      value: event.value,
      label: event.label,
      name: event.name,
      nss: event.nss,
    };
    setProfesorP(profesor);
    await handleSemaforoPre(idRow, event.value);
  };

  const handleSemaforoPre = async (idRow, idPerson) => {
    if (idPerson === semaforoIDperson && idRow === semaforoIDassignment) {
      return;
    }
    try {
      const req = `/docentes/semaforo?id_person=${idPerson}&id_assignment=${idRow}&id_campus=${campusId}&ciclo=${cicloP}&id_direccion=${direccionP.value}`;
      const response = await getRequest(req);
      if (response.s === "OK") {
        setTotalGruposP(response.d.total_grupos);
        setHorasAsignadasP(response.d.horas_asignadas);
        setConsultarColor(response.d.color);
        setHorasGSIP(response.d.horas_GSI);
        setPorcentajeGSIP(response.d.porcentaje_GSI);
        setSemaforoIDperson(idPerson);
        setSemaforoIDassignment(idRow);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleAssign(idRow) {
    if (!preassignmentDataTable[idRow]["id_profesor"]) {
      setLoadingPreassignmentTable(true);
      const assignData = preassignmentDataTable.filter(
        (item) => item["id"] === idRow
      );
      var trueProf = false;
      if (profesorP.length > 0) {
        profesorP.forEach((item) => {
          if (item !== undefined) {
            if (item["idRow"] === idRow) {
              trueProf = true;
            }
          }
        });
        if (trueProf) {
          const assignProf = profesorP.filter((item) => {
            if (item !== undefined) {
              if (item["idRow"] === idRow) {
                return true;
              }
            }
            return false;
          });
          const dataAssignTable = [
            {
              id: assignData[0]["id"],
              id_assignment: assignData[0]["id_assignment"],
              tipo_registro_asistencia:
                assignData[0]["tipo_registro_asistencia"],
              id_tipo_profesor: assignData[0]["id_tipo_profesor"],
              id_profesor: assignProf[0]["value"],
              profDefinitivo: assignProf[0]["nss"],
              nombre_profesor: assignProf[0]["name"] || assignProf[0]["label"],
              fechaAlta: assignData[0]["fecha_alta_subir"] || "",
              asig: totalGruposP || assignData[0]["asig_subir"] || "1",
              fecha_alta_subir: assignData[0]["fecha_alta_subir"],
              asig_subir: totalGruposP || assignData[0]["asig_subir"],
              grupo: assignData[0]["grupo"],
              materia: assignData[0]["materia"],
              descripcion: assignData[0]["descripcion"],
              lunes: assignData[0]["lunes"],
              martes: assignData[0]["martes"],
              miercoles: assignData[0]["miercoles"],
              jueves: assignData[0]["jueves"],
              viernes: assignData[0]["viernes"],
              sabado: assignData[0]["sabado"],
              domingo: assignData[0]["domingo"],
              horario_fechas: assignData[0]["horario_fechas"],
            },
          ];
          try {
            let horarios = [];
            if (assignData[0]["horario_fechas"][0] !== "") {
              horarios = assignData[0]["horario_fechas"].map((item) => {
                const dayOfTheWeek =
                  moment(item).format("dddd").toLocaleLowerCase() ===
                  "miércoles"
                    ? "miercoles"
                    : moment(item).format("dddd").toLocaleLowerCase() ===
                      "sábado"
                    ? "sabado"
                    : moment(item).format("dddd").toLocaleLowerCase();
                return {
                  dia: dayOfTheWeek,
                  inicia: dataAssignTable[0][dayOfTheWeek].substring(0, 5),
                  termina: dataAssignTable[0][dayOfTheWeek].substring(6, 11),
                  fecha: item,
                };
              });
            } else {
              if (dataAssignTable[0].lunes !== "") {
                horarios.push({
                  dia: "lunes",
                  inicia: dataAssignTable[0].lunes.substring(0, 5),
                  termina: dataAssignTable[0].lunes.substring(6, 11),
                });
              }
              if (dataAssignTable[0].martes !== "") {
                horarios.push({
                  dia: "martes",
                  inicia: dataAssignTable[0].martes.substring(0, 5),
                  termina: dataAssignTable[0].martes.substring(6, 11),
                });
              }
              if (dataAssignTable[0].miercoles !== "") {
                horarios.push({
                  dia: "miercoles",
                  inicia: dataAssignTable[0].miercoles.substring(0, 5),
                  termina: dataAssignTable[0].miercoles.substring(6, 11),
                });
              }
              if (dataAssignTable[0].jueves !== "") {
                horarios.push({
                  dia: "jueves",
                  inicia: dataAssignTable[0].jueves.substring(0, 5),
                  termina: dataAssignTable[0].jueves.substring(6, 11),
                });
              }
              if (dataAssignTable[0].viernes !== "") {
                horarios.push({
                  dia: "viernes",
                  inicia: dataAssignTable[0].viernes.substring(0, 5),
                  termina: dataAssignTable[0].viernes.substring(6, 11),
                });
              }
              if (dataAssignTable[0].sabado !== "") {
                horarios.push({
                  dia: "sabado",
                  inicia: dataAssignTable[0].sabado.substring(0, 5),
                  termina: dataAssignTable[0].sabado.substring(6, 11),
                });
              }
              if (dataAssignTable[0].domingo !== "") {
                horarios.push({
                  dia: campusId === 12 ? "sabado" : "domingo",
                  inicia: dataAssignTable[0].domingo.substring(0, 5),
                  termina: dataAssignTable[0].domingo.substring(6, 11),
                });
              }
            }
            const payload = {
              id_asignacion: dataAssignTable[0].id_assignment,
              id_campus: campusId,
              id_person: dataAssignTable[0].id_profesor,
              id_tipo_profesor: parseInt(
                dataAssignTable[0].id_tipo_profesor
              ).toString(),
              tipo_registro_asistencia:
                campusId === 12
                  ? "periodo_fijo"
                  : dataAssignTable[0].tipo_registro_asistencia,
              fecha_alta: dataAssignTable[0].fechaAlta,
              horario: horarios,
            };
            let url = "";
            campusId === 12
              ? (url = "/asignaciones/asignar-docente-online")
              : (url = "/asignaciones/asignar-docente");
            const response = await postRequest(url, payload);
            if (response.s === "OK") {
              let newTotalGrupos = "";
              try {
                const req = `/docentes/semaforo?id_person=${profesorP[idRow]["value"]}&id_assignment=${idRow}&id_campus=${campusId}&ciclo=${cicloP}&id_direccion=${direccionP.value}`;
                const response = await getRequest(req);
                if (response.s === "OK") {
                  newTotalGrupos = response.d.total_grupos;
                  setTotalGruposP(response.d.total_grupos);
                  setHorasAsignadasP(response.d.horas_asignadas);
                  setHorasGSIP(response.d.horas_GSI);
                  setConsultarColor(response.d.color);
                  setPorcentajeGSIP(response.d.porcentaje_GSI);
                  setSemaforoIDperson(profesorP[idRow]["value"]);
                  setSemaforoIDassignment(idRow);
                } else {
                  enqueueSnackbar(response.m, {
                    variant: "error",
                    autoHideDuration: null,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  });
                }
              } catch (error) {
                console.log(error);
              }
              let dataTable = [...preassignmentDataTable];
              dataAssignTable[0].asig = newTotalGrupos;
              dataAssignTable[0].asig_subir = newTotalGrupos;
              dataTable[idRow] = dataAssignTable[0];

              setPreassignmentDataTable(dataTable);
              enqueueSnackbar(response.m, {
                variant: "success",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
            } else {
              enqueueSnackbar(response.m, {
                variant: "error",
                autoHideDuration: null,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          enqueueSnackbar("No se ha seleccionado un profesor", {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } else {
        enqueueSnackbar("No se ha asignado a ningún profesor", {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setLoadingPreassignmentTable(false);
    }
  }

  async function handleDeleteAssign(idRow) {
    if (preassignmentDataTable[idRow].id_profesor) {
      setLoadingPreassignmentTable(true);
      const assignData = preassignmentDataTable.filter((item) => {
        if (item !== undefined) {
          if (item["id"] === idRow) return item;
        }
        return false;
      });
      const dataAssignTable = {
        grupo: assignData[0]["grupo"],
        materia: assignData[0]["materia"],
        descripcion: assignData[0]["descripcion"],
        lunes: assignData[0]["lunes"],
        martes: assignData[0]["martes"],
        miercoles: assignData[0]["miercoles"],
        jueves: assignData[0]["jueves"],
        viernes: assignData[0]["viernes"],
        sabado: assignData[0]["sabado"],
        domingo: assignData[0]["domingo"],
        id: assignData[0]["id"],
        id_assignment: assignData[0]["id_assignment"],
        id_tipo_profesor: assignData[0]["id_tipo_profesor"],
        tipo_registro_asistencia: assignData[0]["tipo_registro_asistencia"],
        id_profesor: "",
        profDefinitivo: "",
        nombre_profesor: "",
        fechaAlta: "",
        asig: "",
        fecha_alta_subir: assignData[0]["fecha_alta_subir"],
        asig_subir: assignData[0]["asig_subir"],
        horario_fechas: assignData[0]["horario_fechas"],
      };

      try {
        const payload = {
          id_asignacion: dataAssignTable.id_assignment,
          id_profesor: assignData[0]["id_profesor"],
          id_motivo_baja: "14",
          comentario: "-",
          fecha_baja: dataAssignTable.fecha_alta_subir,
        };
        let url = "";
        campusId === 12
          ? (url = "/asignaciones/baja-online")
          : (url = "/asignaciones/baja");
        const response = await postRequest(url, payload);
        if (response.s === "OK") {
          let dataTable = [...preassignmentDataTable];
          dataTable[idRow] = dataAssignTable;
          let profesores2 = [...profesorP];
          profesores2[idRow] = "";
          setProfesorP(profesores2);
          setPreassignmentDataTable(dataTable);
          setTotalGruposP("");
          setHorasAsignadasP("");
          setHorasGSIP("");
          setConsultarColor("#e5e5e5");
          setPorcentajeGSIP("");
          setSemaforoIDperson("");
          setSemaforoIDassignment("");
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
      setLoadingPreassignmentTable(false);
    }
  }

  async function agregarNuevaFila(nroFilas) {
    let filas = [...nroFilas];
    let dates = [...datesValues];
    let days = [...daysValues];
    let hor_iniciales = [...horaIniciaValues];
    let min_iniciales = [...minutosIniciaValues];
    let hor_finales = [...horaTerminaValues];
    let min_finales = [...minutosTerminaValues];
    let height = heightAsignar;
    if (nroFilas.length < 3) {
      filas.push(filas.length + 1);
      dates.push("");
      days.push("");
      hor_iniciales.push("");
      min_iniciales.push("");
      hor_finales.push("");
      min_finales.push("");
      setDatesValues(dates);
      setDaysValues(days);
      setHoraIniciaValues(hor_iniciales);
      setMinutosIniciaValues(min_iniciales);
      setHoraTerminaValues(hor_finales);
      setMinutosTerminaValues(min_finales);
      setHeightAsignar(550);
      setNroFilas(filas);
    } else {
      height += 45;
      filas.push(filas.length + 1);
      dates.push("");
      hor_iniciales.push("");
      min_iniciales.push("");
      hor_finales.push("");
      min_finales.push("");
      setDatesValues(dates);
      setDaysValues(days);
      setHoraIniciaValues(hor_iniciales);
      setMinutosIniciaValues(min_iniciales);
      setHoraTerminaValues(hor_finales);
      setMinutosTerminaValues(min_finales);
      setHeightAsignar(height);
      setNroFilas(filas);
    }
  }

  async function deleteRow(nro) {
    if (disabled === false) {
      switch (nroFilas.length) {
        case 1:
          setDatesValues([]);
          setDaysValues([]);
          setHoraIniciaValues([]);
          setMinutosIniciaValues([]);
          setHoraTerminaValues([]);
          setMinutosTerminaValues([]);
          setNroFilas([]);
          break;
        case 2:
          switch (nro) {
            case 0:
              setDatesValues([datesValues[1]]);
              setDaysValues([daysValues[1]]);
              setHoraIniciaValues([horaIniciaValues[1]]);
              setMinutosIniciaValues([minutosIniciaValues[1]]);
              setHoraTerminaValues([horaTerminaValues[1]]);
              setMinutosTerminaValues([minutosTerminaValues[1]]);
              setNroFilas([1]);
              break;
            default:
              setDatesValues([datesValues[0]]);
              setDaysValues([daysValues[0]]);
              setHoraIniciaValues([horaIniciaValues[0]]);
              setMinutosIniciaValues([minutosIniciaValues[0]]);
              setHoraTerminaValues([horaTerminaValues[0]]);
              setMinutosTerminaValues([minutosTerminaValues[0]]);
              setNroFilas([1]);
              break;
          }
          break;
        case 3:
          switch (nro) {
            case 0:
              setDatesValues([datesValues[1], datesValues[2]]);
              setDaysValues([daysValues[1], daysValues[2]]);
              setHoraIniciaValues([horaIniciaValues[1], horaIniciaValues[2]]);
              setMinutosIniciaValues([
                minutosIniciaValues[1],
                minutosIniciaValues[2],
              ]);
              setHoraTerminaValues([
                horaTerminaValues[1],
                horaTerminaValues[2],
              ]);
              setMinutosTerminaValues([
                minutosTerminaValues[1],
                minutosTerminaValues[2],
              ]);
              setNroFilas([1, 2]);
              break;
            case 1:
              setDatesValues([datesValues[0], datesValues[2]]);
              setDaysValues([daysValues[0], daysValues[2]]);
              setHoraIniciaValues([horaIniciaValues[0], horaIniciaValues[2]]);
              setMinutosIniciaValues([
                minutosIniciaValues[0],
                minutosIniciaValues[2],
              ]);
              setHoraTerminaValues([
                horaTerminaValues[0],
                horaTerminaValues[2],
              ]);
              setMinutosTerminaValues([
                minutosTerminaValues[0],
                minutosTerminaValues[2],
              ]);
              setNroFilas([1, 2]);
              break;
            default:
              setDatesValues([datesValues[0], datesValues[1]]);
              setDaysValues([daysValues[0], daysValues[1]]);
              setHoraIniciaValues([horaIniciaValues[0], horaIniciaValues[1]]);
              setMinutosIniciaValues([
                minutosIniciaValues[0],
                minutosIniciaValues[1],
              ]);
              setHoraTerminaValues([
                horaTerminaValues[0],
                horaTerminaValues[1],
              ]);
              setMinutosTerminaValues([
                minutosTerminaValues[0],
                minutosTerminaValues[1],
              ]);
              setNroFilas([1, 2]);
              break;
          }
          break;
        default:
          let filas = [...nroFilas];
          let dates = [...datesValues];
          let days = [...daysValues];
          let hor_iniciales = [...horaIniciaValues];
          let min_iniciales = [...minutosIniciaValues];
          let hor_finales = [...horaTerminaValues];
          let min_finales = [...minutosTerminaValues];
          let height = heightAsignar;
          height -= 45;
          filas.pop();

          const datesCombined = [];
          const daysCombined = [];
          const hor_iniciaCombined = [];
          const min_iniciaCombined = [];
          const hor_terminaCombined = [];
          const min_terminaCombined = [];
          for (let i = 0; i < nroFilas.length; i++) {
            if (nro !== i) {
              datesCombined.push(dates[i]);
              daysCombined.push(days[i]);
              hor_iniciaCombined.push(hor_iniciales[i]);
              min_iniciaCombined.push(min_iniciales[i]);
              hor_terminaCombined.push(hor_finales[i]);
              min_terminaCombined.push(min_finales[i]);
            }
          }
          setDatesValues(datesCombined);
          setDaysValues(daysCombined);
          setHoraIniciaValues(hor_iniciaCombined);
          setMinutosIniciaValues(min_iniciaCombined);
          setHoraTerminaValues(hor_terminaCombined);
          setMinutosTerminaValues(min_terminaCombined);
          setHeightAsignar(height);
          setNroFilas(filas);
          break;
      }
    }
  }

  async function showPreassignmentTable() {
    setLoadingPreassignmentTable(true);
    setTotalGruposP("");
    setHorasAsignadasP("");
    setHorasGSIP("");
    setConsultarColor("#e5e5e5");
    setPorcentajeGSIP("");
    setSemaforoIDperson("");
    setSemaforoIDassignment("");
    let valido = true;
    if (cicloP === "") {
      enqueueSnackbar("Falta llenar campo Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (escuelaP === "") {
      enqueueSnackbar("Falta seleccionar Escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (direccionP === "") {
      enqueueSnackbar("Falta seleccionar Dirección", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        const consulta = `/buscar?tipo=preasignacion_buscar&valor=id_campus:${campusId},ciclo:${cicloP},id_escuela:${escuelaP.value},id_direccion:${direccionP.value}`;
        const response = await getRequest(consulta);
        if (response.s === "OK") {
          if (
            response.d === "" ||
            response.d.length === 0 ||
            response.d === null
          ) {
            setPreassignmentDataTable([]);
            enqueueSnackbar(
              "No se ha encontrado información con los datos ingresados",
              {
                variant: "info",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }
            );
          } else {
            const data = [];
            let profesores = [];
            response.d.forEach((item, i) => {
              if (
                item.horario.id_profesor_titular === "" ||
                item.horario.id_profesor_titular === null ||
                item.horario.id_profesor_titular === undefined
              ) {
                data.push({
                  grupo: item.grupo,
                  materia: item.clave_materia,
                  descripcion: item.nombre_materia,
                  lunes: item.horario.lunes,
                  martes: item.horario.martes,
                  miercoles: item.horario.miercoles,
                  jueves: item.horario.jueves,
                  viernes: item.horario.viernes,
                  sabado: item.horario.sabado,
                  domingo: item.horario.domingo,
                  id: i,
                  id_assignment: item.id_assignment,
                  id_tipo_profesor: item.id_tipo_profesor,
                  tipo_registro_asistencia: item.tipo_registro_asistencia,
                  id_profesor: "",
                  profDefinitivo: "",
                  nombre_profesor: "",
                  fechaAlta: "",
                  asig: "",
                  fecha_alta_subir: item.fecha_alta || "",
                  asig_subir: item.total_asignaciones || "0",
                  horario_fechas: item.horario_fechas,
                });
                profesores.push("");
              } else {
                data.push({
                  id: i,
                  id_assignment: item.id_assignment,
                  tipo_registro_asistencia: item.tipo_registro_asistencia,
                  id_tipo_profesor: item.id_tipo_profesor,
                  id_profesor: item.horario.id_profesor_titular,
                  profDefinitivo: item.horario.clave_profesor_titular,
                  nombre_profesor: item.horario.nombre_profesor_titular,
                  fechaAlta: item.fecha_alta || "",
                  asig: item.total_asignaciones || "0",
                  fecha_alta_subir: item.fecha_alta || "",
                  asig_subir: item.total_asignaciones || "0",
                  grupo: item.grupo,
                  materia: item.clave_materia,
                  descripcion: item.nombre_materia,
                  lunes: item.horario.lunes,
                  martes: item.horario.martes,
                  miercoles: item.horario.miercoles,
                  jueves: item.horario.jueves,
                  viernes: item.horario.viernes,
                  sabado: item.horario.sabado,
                  domingo: item.horario.domingo,
                  horario_fechas: item.horario_fechas,
                });
                profesores.push({
                  idRow: i,
                  value: item.horario.id_profesor_titular,
                  label: `${item.horario.clave_profesor_titular} - ${item.horario.nombre_profesor_titular}`,
                  nss: item.horario.clave_profesor_titular,
                  name: item.horario.nombre_profesor_titular,
                });
              }
            });
            setProfesorP(profesores);
            setPreassignmentDataTable(data);
          }
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoadingPreassignmentTable(false);
  }

  async function generarProfesorAnterior() {
    setLoadingPreassignmentTable(true);
    let valido = true;
    if (cicloP === "") {
      enqueueSnackbar("Falta llenar campo Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (escuelaP === "") {
      enqueueSnackbar("Falta seleccionar Escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (direccionP === "") {
      enqueueSnackbar("Falta seleccionar Dirección", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        const consulta = `/buscar?tipo=preasignacion_buscar&valor=id_campus:${campusId},ciclo:${cicloP},id_escuela:${escuelaP.value},id_direccion:${direccionP.value},profesor:si`;
        const response = await getRequest(consulta);
        if (response.s === "OK") {
          if (
            response.d === "" ||
            response.d.length === 0 ||
            response.d === null
          ) {
            setPreassignmentDataTable([]);
            enqueueSnackbar(
              "No se ha encontrado información con los datos ingresados",
              {
                variant: "info",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }
            );
          } else {
            const data = [];
            const profesores = [];
            response.d.forEach((item, i) => {
              if (
                item.horario.id_profesor_titular === "" ||
                item.horario.id_profesor_titular === null ||
                item.horario.id_profesor_titular === undefined
              ) {
                data.push({
                  grupo: item.grupo,
                  materia: item.clave_materia,
                  descripcion: item.nombre_materia,
                  lunes: item.horario.lunes,
                  martes: item.horario.martes,
                  miercoles: item.horario.miercoles,
                  jueves: item.horario.jueves,
                  viernes: item.horario.viernes,
                  sabado: item.horario.sabado,
                  domingo: item.horario.domingo,
                  id: i,
                  id_assignment: item.id_assignment,
                  id_tipo_profesor: item.id_tipo_profesor,
                  tipo_registro_asistencia: item.tipo_registro_asistencia,
                  id_profesor: "",
                  profDefinitivo: "",
                  nombre_profesor: "",
                  fechaAlta: "",
                  asig: "",
                  fecha_alta_subir: item.fecha_alta || "",
                  asig_subir: item.total_asignaciones || "0",
                  horario_fechas: item.horario_fechas,
                });
                profesores.push({
                  idRow: i,
                  value: item.horario.person_id,
                  label: `${item.horario.nss} - ${item.horario.person}`,
                  name: item.horario.person,
                });
              } else {
                data.push({
                  id: i,
                  id_assignment: item.id_assignment,
                  tipo_registro_asistencia: item.tipo_registro_asistencia,
                  id_tipo_profesor: item.id_tipo_profesor,
                  id_profesor: item.horario.id_profesor_titular,
                  profDefinitivo: item.horario.clave_profesor_titular,
                  nombre_profesor: item.horario.nombre_profesor_titular,
                  fechaAlta: item.fecha_alta || "",
                  asig: item.total_asignaciones || "0",
                  fecha_alta_subir: item.fecha_alta || "",
                  asig_subir: item.total_asignaciones || "0",
                  grupo: item.grupo,
                  materia: item.clave_materia,
                  descripcion: item.nombre_materia,
                  lunes: item.horario.lunes,
                  martes: item.horario.martes,
                  miercoles: item.horario.miercoles,
                  jueves: item.horario.jueves,
                  viernes: item.horario.viernes,
                  sabado: item.horario.sabado,
                  domingo: item.horario.domingo,
                  horario_fechas: item.horario_fechas,
                });
                profesores.push({
                  idRow: i,
                  value: item.horario.id_profesor_titular,
                  label: `${item.horario.clave_profesor_titular} - ${item.horario.nombre_profesor_titular}`,
                  nss: item.horario.clave_profesor_titular,
                  name: item.horario.nombre_profesor_titular,
                });
              }
            });
            setProfesorP(profesores);
            setPreassignmentDataTable(data);
          }
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoadingPreassignmentTable(false);
  }

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const consultarProfesores = async (id) => {
    try {
      const consulta = `/asignaciones/detalle-consulta?id=${id}`;
      const response = await getRequest(consulta);
      let options = [];
      if (response.s === "OK") {
        for (let i = 0; i < response.d.length; i++) {
          if (response.d[i]["status"] === "Vigente") {
            options.push({
              value: response.d[i]["id"],
              id_person: response.d[i]["id_person"],
              id_assignment_schedule: response.d[i]["id_assignment_schedule"],
              label: `${response.d[i]["clave_docente"]} - ${response.d[i]["nombre_docente"]}`,
            });
          }
        }
        let hash = {};
        options = options.filter((o) =>
          hash[o.label] ? false : (hash[o.label] = true)
        );
        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  async function handleSubmitAsignar() {
    let valido = true;
    var horarioTF = false;
    var horariosFinal = [];
    if (nroFilas.length === 0) {
      enqueueSnackbar("No hay filas agregadas en Asignación de Horario", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return "";
    }
    if (campusId === 12) {
      daysOptions.forEach((item, i) => {
        horariosFinal.push({
          dia: "sabado",
          inicia: `${horasIniciales[i]}:${minutosIniciales[i]}`,
          termina: `${horasFinales[i]}:${minutosFinales[i]}`,
        });
      });
      horarioTF = true;
      if (horariosFinal.length === 0) {
        enqueueSnackbar("No hay horarios asignados", {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        valido = false;
      }
    } else {
      if (horarioType === 1) {
        var horaInicialTabla = "";
        var minutosInicialTabla = "";
        var horaTerminaTabla = "";
        var minutosTerminaTabla = "";
        for (let i = 0; i < nroFilas.length; i++) {
          if (
            daysValues[i] === "" ||
            horaIniciaValues[i] === "" ||
            horaTerminaValues[i] === "" ||
            minutosIniciaValues[i] === "" ||
            minutosTerminaValues[i] === ""
          ) {
            horarioTF = false;
            break;
          } else {
            horarioTF = true;
            switch (daysValues[i].value) {
              case "lunes":
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][0].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][0].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][0].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][0].substring(9, 11)
                );
                break;
              case "martes":
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][1].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][1].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][1].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][1].substring(9, 11)
                );
                break;
              case "miercoles":
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][2].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][2].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][2].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][2].substring(9, 11)
                );
                break;
              case "jueves":
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][3].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][3].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][3].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][3].substring(9, 11)
                );
                break;
              case "viernes":
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][4].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][4].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][4].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][4].substring(9, 11)
                );
                break;
              case "sabado":
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][5].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][5].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][5].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][5].substring(9, 11)
                );
                break;
              default:
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][6].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][6].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][6].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][6].substring(9, 11)
                );
                break;
            }
            if (
              horaIniciaValues[i] < horaInicialTabla ||
              horaTerminaValues[i] > horaTerminaTabla ||
              (horaIniciaValues[i] === horaInicialTabla &&
                minutosIniciaValues[i] < minutosInicialTabla) ||
              (horaTerminaValues[i] === horaTerminaTabla &&
                minutosTerminaValues[i] > minutosTerminaTabla)
            ) {
              enqueueSnackbar(
                "El horario no coincide con lo establecido en la tabla",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }
              );
              valido = false;
              return;
            } else if (
              horaIniciaValues[i] > horaTerminaValues[i] ||
              (horaIniciaValues[i] === horaTerminaValues[i] &&
                minutosIniciaValues[i] > minutosTerminaValues[i])
            ) {
              enqueueSnackbar(
                "El Horario Inicial es Igual o Superior al Horario Final",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }
              );
              valido = false;
              return;
            } else {
              horariosFinal.push({
                dia: daysValues[i].value,
                inicia: `${horaIniciaValues[i]}:${minutosIniciaValues[i]}`,
                termina: `${horaTerminaValues[i]}:${minutosTerminaValues[i]}`,
              });
            }
          }
        }
      } else {
        for (let i = 0; i < nroFilas.length; i++) {
          if (
            datesValues[i] === "" ||
            horaIniciaValues[i] === "" ||
            horaTerminaValues[i] === "" ||
            minutosIniciaValues[i] === "" ||
            minutosTerminaValues[i] === ""
          ) {
            horarioTF = false;
            break;
          } else {
            horarioTF = true;
            let getNroDay = new Date(datesValues[i].value).getDay();
            switch (getNroDay) {
              case 0:
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][0].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][0].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][0].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][0].substring(9, 11)
                );
                break;
              case 1:
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][1].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][1].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][1].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][1].substring(9, 11)
                );
                break;
              case 2:
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][2].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][2].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][2].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][2].substring(9, 11)
                );
                break;
              case 3:
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][3].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][3].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][3].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][3].substring(9, 11)
                );
                break;
              case 4:
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][4].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][4].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][4].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][4].substring(9, 11)
                );
                break;
              case 5:
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][5].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][5].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][5].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][5].substring(9, 11)
                );
                break;
              default:
                horaInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][6].substring(0, 2)
                );
                minutosInicialTabla = parseInt(
                  dataHorarioAsignarFinal[0][6].substring(3, 5)
                );
                horaTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][6].substring(6, 8)
                );
                minutosTerminaTabla = parseInt(
                  dataHorarioAsignarFinal[0][6].substring(9, 11)
                );
                break;
            }
            if (
              horaIniciaValues[i] < horaInicialTabla ||
              horaTerminaValues[i] > horaTerminaTabla ||
              (horaIniciaValues[i] === horaInicialTabla &&
                minutosIniciaValues[i] < minutosInicialTabla) ||
              (horaTerminaValues[i] === horaTerminaTabla &&
                minutosTerminaValues[i] > minutosTerminaTabla)
            ) {
              enqueueSnackbar(
                "El horario no coincide con lo establecido en la tabla",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }
              );
              valido = false;
              return;
            } else if (
              horaIniciaValues[i] > horaTerminaValues[i] ||
              (horaIniciaValues[i] === horaTerminaValues[i] &&
                minutosIniciaValues[i] > minutosTerminaValues[i])
            ) {
              enqueueSnackbar(
                "El Horario Inicial es Igual o Superior al Horario Final",
                {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }
              );
              valido = false;
              return;
            } else {
              horariosFinal.push({
                fecha: datesValues[i].value,
                inicia: `${horaIniciaValues[i]}:${minutosIniciaValues[i]}`,
                termina: `${horaTerminaValues[i]}:${minutosTerminaValues[i]}`,
              });
            }
          }
        }
      }
    }
    if (profesorValue === "") {
      enqueueSnackbar("Falta seleccionar Profesor", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (professorType === "") {
      enqueueSnackbar("Falta seleccionar Tipo de Profesor", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (fechaAlta === "") {
      enqueueSnackbar("Falta llenar campo Fecha de Alta", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (horarioTF === false) {
      enqueueSnackbar("Falta llenar campos en Asignación de Horario", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoadingModalAsignar(true);
      try {
        const payload = {
          id_asignacion: idAssignmentColumn,
          id_person: profesorValue.value,
          id_tipo_profesor: parseInt(professorType.value).toString(),
          id_campus: campusId,
          fecha_alta: fechaAlta,
          tipo_registro_asistencia:
            campusId === 12
              ? "periodo_fijo"
              : horarioType === 2
              ? "fecha_determinada"
              : "periodo_fijo",
          horario: horariosFinal,
        };
        let url = "";
        campusId === 12
          ? (url = "/asignaciones/asignar-docente-online")
          : (url = "/asignaciones/asignar-docente");
        const response = await postRequest(url, payload);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setProfesorValue("");
          setProfessorType("");
          setFechaAlta("");
          setHorarioType(1);
          limpiar();
          setLoadingModalAsignar(false);
          setAsignarModalIsOpen(false);
          setTotalGrupos("");
          setHorasAsignadas("");
          setHorasGsi("");
          setPorcentajeGsi("");
          setSemaforoIDperson("");
          setSemaforoIDassignment("");
          setDataHorarioConsultaFinal([]);
          setDataConsultaFinal([]);
          setConsultaModalIsOpen(false);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
      setLoadingModalAsignar(false);
    }
  }

  async function handleSubmitDesasignar() {
    let valido = true;
    if (profesorValue === "") {
      enqueueSnackbar("Falta seleccionar Clave Profesor", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (motivoBaja === "") {
      enqueueSnackbar("Falta seleccionar Motivo de Baja", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (fechaBaja === "") {
      enqueueSnackbar("Falta seleccionar Fecha de Baja", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (comentario === "") {
      enqueueSnackbar("Falta llenar el campo Comentario", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      if (totalGrupos === 1) {
        setModalComentarioIsOpen(true);
      } else {
        await submitDesasignar();
      }
    }
  }

  async function submitDesasignar() {
    try {
      setLoadingModalDesasignar(true);
      //el idAssignmentColumn es exactamente igual al profesorValue.value
      const payload = {
        id_asignacion: idAssignmentColumn,
        id_profesor: profesorValue.id_person,
        id_motivo_baja: motivoBaja.value,
        comentario: comentario,
        fecha_baja: fechaBaja,
      };
      let url = "";
      campusId === 12
        ? (url = "/asignaciones/baja-online")
        : (url = "/asignaciones/baja");
      const response = await postRequest(url, payload);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setProfesorValue("");
        setMotivoBaja("");
        setFechaBaja("");
        setComentario("");
        setTotalGrupos("");
        setHorasAsignadas("");
        setHorasGsi("");
        setPorcentajeGsi("");
        setSemaforoIDperson("");
        setSemaforoIDassignment("");
        setDataHorarioDesasignarFinal([]);
        setDesasignarModalIsOpen(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingModalDesasignar(false);
  }

  const handleComentarioGrupoDesasignar = async () => {
    setLoadingModalComentario(true);
    await submitDesasignar();
    setLoadingModalComentario(false);
    setModalComentarioIsOpen(false);
  };

  useEffect(() => {
    consultarCatalogo(3).then((data) => {
      setProfessorTypeOptions(data);
    });
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(21).then((data) => setOptionsTipoGrupo(data));
    consultarCatalogo(27).then((data) => {
      const filterData = data.filter(
        (item) => item.label !== "7 - Error Preasignacion"
      );
      setOptionsMotivoBaja(filterData);
    });
  }, []);

  useEffect(() => {
    moment.locale("es");
  }, []);

  const asignacionNormal = [
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ padding: "10px 0px", marginLeft: "-3.2%" }}
    >
      <Grid xs={1} item style={{ marginLeft: "0.1%" }}>
        <label style={{ float: "right" }}>Ciclo(*):</label>
      </Grid>
      <Grid xs={3} item style={{ marginLeft: "-0.5%" }}>
        <input
          className="filter-input"
          value={ciclo}
          onChange={(ev) => setCiclo(ev.target.value)}
        ></input>
      </Grid>
      <Grid xs={1} item style={{ marginLeft: "1.3%" }}>
        <label style={{ float: "right" }}>Escuela:</label>
      </Grid>
      <Grid xs={3} item style={{ zIndex: 99, marginLeft: "-0.5%" }}>
        <Select
          classNamePrefix="select-modal"
          options={optionsEscuela}
          onChange={setEscuela}
          isClearable={true}
        />
      </Grid>
      <Grid xs={1} item>
        <label style={{ float: "right" }}>Dirección:</label>
      </Grid>
      <Grid xs={3} item style={{ zIndex: 99, marginLeft: "-0.5%" }}>
        <Select
          classNamePrefix="select-modal"
          options={optionsDireccion}
          value={direccion}
          onChange={setDireccion}
          isClearable={true}
        />
      </Grid>
      <Grid xs={1} item>
        <label style={{ float: "right" }}>Módulo:</label>
      </Grid>
      <Grid xs={3} item style={{ marginLeft: "-0.5%" }}>
        <input
          className="filter-input"
          style={{ width: "100%" }}
          value={modulo}
          onChange={(ev) => setModulo(ev.target.value)}
        ></input>
      </Grid>
      <Grid xs={1} sm={2} item style={{ marginLeft: "-7%" }}>
        <label style={{ float: "right" }}>Tipo de grupo:</label>
      </Grid>
      <Grid xs={3} item style={{ zIndex: 98, marginLeft: "-0.5%" }}>
        <Select
          classNamePrefix="select-modal"
          options={optionsTipoGrupo}
          value={tipoGrupo}
          onChange={setTipoGrupo}
          isClearable={true}
        />
      </Grid>
      <Grid xs={1} item>
        <label style={{ float: "right" }}>Grupo(*):</label>
      </Grid>
      <Grid xs={3} item style={{ marginLeft: "-0.5%" }}>
        <input
          className="filter-input"
          style={{ width: "100%" }}
          value={grupo}
          onChange={(ev) => setGrupo(ev.target.value)}
        ></input>
      </Grid>
      <Grid xs={1} item>
        <label style={{ float: "right" }}>Materia:</label>
      </Grid>
      <Grid xs={3} item style={{ zIndex: 98, marginLeft: "-0.5%" }}>
        <input
          className="filter-input"
          style={{ width: "100%" }}
          value={materia}
          maxLength={10}
          onChange={(e) => setMateria(e.target.value)}
        ></input>
      </Grid>
      <Grid xs={8}></Grid>
      <Grid xs={6} style={{ marginLeft: "5%", marginRight: "-5%" }}>
        <NewButton
          customText="Preasignación"
          noIcon
          style={{ padding: "5px 15px" }}
          onClick={showModalPreasignacion}
        />
      </Grid>
      <Grid xs={6} container justifyContent="flex-end">
        <NewButton
          customText="Buscar"
          noIcon
          style={{ padding: "5px 15px" }}
          onClick={handleClick}
        />
      </Grid>
    </Grid>,
  ];

  const asignacionOnline = [
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ padding: "10px 0px", marginLeft: "-3.2%" }}
    >
      <Grid xs={1} item>
        <label style={{ float: "right" }}>Campus(*):</label>
      </Grid>
      <Grid xs={3} item style={{ marginLeft: "-0.5%", zIndex: 99 }}>
        <Select
          classNamePrefix="select-modal"
          options={campusOptions}
          value={campus}
          onChange={setCampus}
        />
      </Grid>
      <Grid xs={1} item style={{ marginLeft: "1%" }}>
        <label style={{ float: "right" }}>Módulo:</label>
      </Grid>
      <Grid xs={3} item style={{ marginLeft: "-0.5%" }}>
        <input
          className="filter-input"
          style={{ width: "100%" }}
          value={modulo}
          onChange={(ev) => setModulo(ev.target.value)}
        ></input>
      </Grid>
      <Grid xs={4}></Grid>
      <Grid xs={1} item style={{ marginLeft: "0.1%" }}>
        <label style={{ float: "right" }}>Ciclo(*):</label>
      </Grid>
      <Grid xs={3} item style={{ marginLeft: "-0.5%" }}>
        <input
          className="filter-input"
          value={ciclo}
          onChange={(ev) => setCiclo(ev.target.value)}
        ></input>
      </Grid>
      <Grid xs={1} item style={{ marginLeft: "1.3%" }}>
        <label style={{ float: "right" }}>Escuela:</label>
      </Grid>
      <Grid xs={3} item style={{ zIndex: 99, marginLeft: "-0.5%" }}>
        <Select
          classNamePrefix="select-modal"
          options={optionsEscuela}
          onChange={setEscuela}
          isClearable={true}
        />
      </Grid>
      <Grid xs={1} item>
        <label style={{ float: "right" }}>Dirección:</label>
      </Grid>
      <Grid xs={3} item style={{ zIndex: 99, marginLeft: "-0.5%" }}>
        <Select
          classNamePrefix="select-modal"
          options={optionsDireccion}
          value={direccion}
          onChange={setDireccion}
          isClearable={true}
        />
      </Grid>
      <Grid xs={1} item>
        <label style={{ float: "right" }}>Materia:</label>
      </Grid>
      <Grid xs={3} item style={{ zIndex: 98, marginLeft: "-0.5%" }}>
        <input
          className="filter-input"
          style={{ width: "100%" }}
          value={materia}
          maxLength={10}
          onChange={(e) => setMateria(e.target.value)}
        ></input>
      </Grid>
      <Grid xs={1} sm={2} item style={{ marginLeft: "-7%" }}>
        <label style={{ float: "right" }}>Tipo de grupo:</label>
      </Grid>
      <Grid xs={3} item style={{ zIndex: 98, marginLeft: "-0.5%" }}>
        <Select
          classNamePrefix="select-modal"
          options={optionsTipoGrupo}
          value={tipoGrupo}
          onChange={setTipoGrupo}
          isClearable={true}
        />
      </Grid>
      <Grid xs={1} item>
        <label style={{ float: "right" }}>Grupo(*):</label>
      </Grid>
      <Grid xs={3} item style={{ marginLeft: "-0.5%" }}>
        <input
          className="filter-input"
          style={{ width: "100%" }}
          value={grupo}
          onChange={(ev) => setGrupo(ev.target.value)}
        ></input>
      </Grid>
      {/* <Grid xs={8}></Grid> */}
      <Grid xs={6} style={{ marginLeft: "5%", marginRight: "-5%" }}>
        <NewButton
          customText="Preasignación"
          noIcon
          style={{ padding: "5px 15px" }}
          onClick={showModalPreasignacion}
        />
      </Grid>
      <Grid xs={6} container justifyContent="flex-end">
        <NewButton
          customText="Buscar"
          noIcon
          style={{ padding: "5px 15px" }}
          onClick={handleClick}
        />
      </Grid>
    </Grid>,
  ];

  useEffect(() => {
    getRule53();
  }, []);

  return (
    <Contenedor title="Asignaciones / Asignaciones">
      {campusId === 12 ? asignacionOnline : asignacionNormal}

      <div style={{ height: "calc(100% - 225px)", marginTop: "20px" }}>
        <DataTable
          headers={campusId === 12 ? headersOnline : headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4]}
          paginate
          bordes
        />
      </div>

      <NewModal
        title="Preasignación"
        open={preasignacionModalIsOpen}
        handleClose={() => {
          setCicloP("");
          setPorcentajeGSIP("");
          setHorasAsignadasP("");
          setEscuelaP("");
          setDireccionP("");
          setHorasGSIP("");
          setTotalGruposP("");
          setProfesorP([]);
          setConsultarColor("");
          setPreassignmentDataTable([]);
          setPreasignacionModalIsOpen(false);
        }}
        height={830}
        maxWidth={1850}
        noSaveBtn
      >
        <section className="preassignment">
          <div className="preassignment-filters">
            <div className="preassignment-filters_option">
              <label>Campus:</label>
              <input disabled className="filter-input" value={campusName} />
            </div>
            <div className="preassignment-filters_option">
              <label>Ciclo(*):</label>
              <input
                className="filter-input"
                style={{ width: "260px", marginLeft: "9%" }}
                onChange={(e) => setCicloP(e.target.value)}
              />
            </div>
            <div
              className="preassignment-filters_option"
              style={{ marginLeft: "55%" }}
            >
              <NewButton
                customText="Buscar"
                noIcon
                style={{ padding: "5px 15px" }}
                onClick={showPreassignmentTable}
              />
            </div>
            <div className="preassignment-filters_option">
              <label style={{ marginLeft: "auto" }}>Porcentaje GSI:</label>
              <input
                type="number"
                className="filter-input"
                value={porcentajeGSIP}
                disabled
                // onChange={(ev) => setPorcentajeGSIP(ev.target.value)}
              />
            </div>
            <div className="preassignment-filters_option">
              <label style={{ marginLeft: "auto" }}>Horas Asignadas:</label>
              <input
                disabled
                className={"filterinputfunction"}
                style={{
                  width: "100px",
                  backgroundColor: consultarColor ? consultarColor : "#e5e5e5",
                  color: "white",
                }}
                value={horasAsignadasP}
              />
            </div>
            <div
              className="preassignment-filters_option"
              style={{ zIndex: 99 }}
            >
              <label>Escuela(*):</label>
              <Select
                className="preassignment-filters_option-select"
                classNamePrefix="select-modal"
                options={optionsEscuela}
                onChange={setEscuelaP}
              />
            </div>
            <div
              className="preassignment-filters_option"
              style={{ zIndex: 99 }}
            >
              <label>Dirección(*):</label>
              <Select
                className="preassignment-filters_option-select"
                classNamePrefix="select-modal"
                options={optionsDireccion}
                onChange={setDireccionP}
              />
            </div>
            <div
              className="preassignment-filters_option"
              style={{ marginLeft: "35%" }}
            >
              <NewButton
                customText="Generar profesor anterior"
                noIcon
                style={{ padding: "5px 15px" }}
                onClick={generarProfesorAnterior}
              />
            </div>
            <div className="preassignment-filters_option">
              <label style={{ marginLeft: "auto" }}>Horas GSI:</label>
              <input
                type="number"
                className="filter-input"
                value={horasGSIP}
                disabled
                // onChange={(ev) => setHorasGSIP(ev.target.value)}
              />
            </div>
            <div className="preassignment-filters_option">
              <label style={{ marginLeft: "auto" }}>Total de grupos:</label>
              <input
                type="number"
                className="filter-input"
                value={totalGruposP}
                disabled
                // onChange={(ev) => setTotalGruposP(ev.target.value)}
              />
            </div>
          </div>

          <div className="preassignment-table">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box sx={{ height: 400, width: "100%" }}>
                  {preassignmentDataTable && (
                    <DataGrid
                      rows={preassignmentDataTable}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                      loading={loadingPreassignmentTable}
                      getRowHeight={() => "auto"}
                      sx={{
                        paddingBottom: -10,
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </section>
      </NewModal>

      <NewModal
        title="Consulta"
        open={consultaModalIsOpen}
        handleClose={() => {
          setTotalGrupos("");
          setHorasAsignadas("");
          setHorasGsi("");
          setPorcentajeGsi("");
          setSemaforoIDperson("");
          setSemaforoIDassignment("");
          setDataHorarioConsultaFinal([]);
          setDataConsultaFinal([]);
          setConsultarColor("");
          setConsultaModalIsOpen(false);
        }}
        loading={loadingModalConsulta}
        height={830}
        maxWidth={1500}
        noButtons
      >
        <div className="inputs-asignaciones" style={{ marginTop: "25px" }}>
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid container item xs={12} sm={12}>
              <Grid container style={{ marginTop: "-0.5%" }}>
                <Grid item xs={1} md={1} style={{ marginLeft: "-0.1%" }}>
                  <label>Ciclo:</label>
                </Grid>
                <Grid xs={2} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "310px" }}
                    value={assignmentDetail.ciclo}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "8.1%" }}>
                  <label>Escuela:</label>
                </Grid>
                <Grid xs={5} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{
                      width: "100px",
                      marginRight: "10px",
                    }}
                    value={assignmentDetail.clave_escuela}
                  />
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "300px" }}
                    value={assignmentDetail.nombre_escuela}
                  />
                </Grid>
                {campusId === 12 && [
                  <Grid item xs={2} md={2} style={{ marginLeft: "-14%" }}>
                    <label>Módulo:</label>
                  </Grid>,
                  <Grid item xs={1} md={1}>
                    <input
                      disabled
                      className="filter-input"
                      style={{ width: "160px" }}
                      value={assignmentDetail.modulo}
                    />
                  </Grid>,
                ]}
              </Grid>
              <Grid container style={{ marginTop: "0.5%" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "-0.15%" }}>
                  <label>Dirección:</label>
                </Grid>
                <Grid xs={4} md={4}>
                  <input
                    disabled
                    className="filter-input"
                    style={{
                      width: "100px",
                      marginRight: "10px",
                    }}
                    value={assignmentDetail.clave_direccion}
                  />
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "200px" }}
                    value={assignmentDetail.nombre_direccion}
                  />
                </Grid>
                <Grid xs={2} md={2} style={{ marginLeft: "-10.5%" }}>
                  <label>Tipo de Grupo:</label>
                </Grid>
                <Grid xs={3} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{
                      width: "100px",
                      marginRight: "10px",
                    }}
                    value={assignmentDetail.clave_tipo_grupo}
                  />
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "300px" }}
                    value={assignmentDetail.tipo_grupo}
                  />
                </Grid>
                <Grid item xs={2} md={2} style={{ marginLeft: "-14%" }}>
                  <label>Tipo Asistencia:</label>
                </Grid>
                <Grid item xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160px" }}
                    value={assignmentDetail.tipo_asistencia}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "0.5%" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "-0.2%" }}>
                  <label>Grupo:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "310px" }}
                    value={assignmentDetail.grupo}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "16.5%" }}>
                  <label>Materia:</label>
                </Grid>
                <Grid xs={5} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{
                      width: "100px",
                      marginRight: "10px",
                    }}
                    value={assignmentDetail.clave_materia}
                  />
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "300px" }}
                    value={assignmentDetail.materia}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "-5.7%" }}>
                  <label>Complementario:</label>
                </Grid>
                <Grid xs={1} md={1} style={{ marginRight: "3%" }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160px" }}
                    value={
                      assignmentDetail.complementario === true ? "SÍ" : "NO"
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "0.5%" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "0.2%" }}>
                  <label>Periodo:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "310px" }}
                    value={periodo}
                  />
                </Grid>
                {campusId === 12 && [
                  <Grid
                    xs={1}
                    md={1}
                    style={{ marginLeft: "16.3%", marginRight: "-0.6%" }}
                  >
                    <label>Horas:</label>
                  </Grid>,
                  <Grid item xs={5} md={5} style={{ marginTop: "-0.5%" }}>
                    <input
                      disabled
                      className="filter-input"
                      style={{
                        width: "410px",
                        marginRight: "7px",
                      }}
                      value={assignmentDetail.horas}
                    />
                  </Grid>,
                  <Grid xs={1} md={1} style={{ marginLeft: "-5.7%" }}>
                    <label>Semanas:</label>
                  </Grid>,
                  <Grid xs={1} md={1} style={{ marginRight: "3%" }}>
                    <input
                      disabled
                      className="filter-input"
                      value={assignmentDetail.total_semanas}
                      style={{ width: "160px" }}
                    />
                  </Grid>,
                ]}
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            height: "65px",
            width: "80%",
            margin: "auto",
            marginTop: "3%",
            marginBottom: "1%",
          }}
        >
          <DataTable
            headers={headersTablaHorarioConsulta}
            data={dataHorarioConsultaFinal}
            loading={loadingHorarioConsulta}
            centrar={[1, 2, 3, 4, 5, 6, 7]}
            bordes
          />
        </div>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: "10px" }}
        >
          <Grid xs={1} md={1} style={{ marginRight: "-0.5%" }}>
            <label>Total Grupos:</label>
          </Grid>
          <Grid xs={1} md={1}>
            <input
              disabled
              className="filter-input"
              style={{ width: "100px" }}
              value={totalGrupos}
            />
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            style={{ marginLeft: "3%", marginRight: "-6.5%" }}
          >
            <label>Horas Asignadas:</label>
          </Grid>
          <Grid xs={1} md={1}>
            <input
              disabled
              className={"filterinputfunction"}
              style={{
                width: "100px",
                backgroundColor: consultarColor ? consultarColor : "#e5e5e5",
                color: "white",
              }}
              value={horasAsignadas}
            />
          </Grid>
          <Grid
            item
            xs={1}
            md={1}
            style={{ marginLeft: "3%", marginRight: "-2%" }}
          >
            <label>Horas GSI:</label>
          </Grid>
          <Grid item xs={1} md={1}>
            <input
              disabled
              className="filter-input"
              style={{ width: "100px" }}
              value={horasGsi}
            />
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            style={{ marginLeft: "3%", marginRight: "-8%" }}
          >
            <label>Porcentaje GSI:</label>
          </Grid>
          <Grid item xs={1} md={1}>
            <input
              disabled
              className="filter-input"
              style={{ width: "100px" }}
              value={porcentajeGsi}
            />
          </Grid>
        </Grid>
        <div
          style={{
            height: "calc(100% - 295px)",
            width: "calc(100% - 175px)",
            margin: "auto",
            paddingBottom: "1%",
          }}
          id="Pagos"
        >
          <Tabs
            namesTabs={tabsNamesConsulta}
            containersTabs={containersTabsConsulta}
          />
        </div>
      </NewModal>

      <NewModal
        title="Asignar Docente"
        open={asignarModalIsOpen}
        handleClose={() => {
          setProfesorValue("");
          setProfessorType("");
          setHorarioType(1);
          setFechaAlta("");
          setDaysValues([]);
          setDatesValues([]);
          setHoraIniciaValues([]);
          setMinutosIniciaValues([]);
          setHoraTerminaValues([]);
          setMinutosTerminaValues([]);
          setNroFilas([]);
          setHeightAsignar(550);
          setTotalGrupos("");
          setHorasAsignadas("");
          setHorasGsi("");
          setPorcentajeGsi("");
          setSemaforoIDperson("");
          setSemaforoIDassignment("");
          setDataHorarioAsignarFinal([]);
          setConsultarColor("");
          setDisabled(false);
          setDisabledRegistroAsistencia(false);
          setOcultarAsignacion(false);
          setAsignarModalIsOpen(false);
        }}
        loading={loadingModalAsignar}
        handleSubmit={handleSubmitAsignar}
        height={830}
        maxWidth={1500}
      >
        <Grid style={{ padding: "50px" }}>
          <Grid container spacing={2} style={{ marginLeft: "-2%" }}>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Ciclo:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className="filter-input"
                style={{ width: "290px" }}
                value={assignmentDetail.ciclo}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "0.3%" }}>
              <label style={{ float: "right" }}>Escuela:</label>
            </Grid>
            <Grid item xs={5} md={5}>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={assignmentDetail.clave_escuela}
              />
              <input
                disabled
                className="filter-input"
                style={{ width: "300px" }}
                value={assignmentDetail.nombre_escuela}
              />
            </Grid>
            {campusId === 12 && [
              <Grid
                item
                xs={1}
                md={2}
                style={{ paddingLeft: 0, marginLeft: "-10.9%" }}
              >
                <label style={{ float: "right" }}>Módulo:</label>
              </Grid>,
              <Grid item xs={1} style={{ marginLeft: "-0.5%" }}>
                <input
                  disabled
                  className="filter-input"
                  value={assignmentDetail.modulo}
                  style={{ width: "150px" }}
                />
              </Grid>,
            ]}
          </Grid>
          <Grid container spacing={2} style={{ marginLeft: "-2%" }}>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Dirección:</label>
            </Grid>
            <Grid item xs={3} container>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={assignmentDetail.clave_direccion}
              />
              <input
                disabled
                className="filter-input"
                style={{ width: "180px" }}
                value={assignmentDetail.nombre_direccion}
              />
            </Grid>
            <Grid
              item
              xs={1}
              md={2}
              style={{ paddingLeft: 0, marginLeft: "-8%" }}
            >
              <label style={{ float: "right" }}>Tipo de Grupo:</label>
            </Grid>
            <Grid item xs={5} md={5}>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={assignmentDetail.clave_tipo_grupo}
              />
              <input
                disabled
                className="filter-input"
                style={{ width: "300px" }}
                value={assignmentDetail.tipo_grupo}
              />
            </Grid>
            <Grid
              item
              xs={1}
              md={2}
              style={{ paddingLeft: 0, marginLeft: "-10.9%" }}
            >
              <label style={{ float: "right" }}>Tipo Asistencia:</label>
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "-0.5%" }}>
              <input
                disabled
                className="filter-input"
                value={assignmentDetail.tipo_asistencia}
                style={{ width: "150px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginLeft: "-2%" }}>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Grupo:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className="filter-input"
                style={{ width: "290px" }}
                value={assignmentDetail.grupo}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "0.3%" }}>
              <label style={{ float: "right" }}>Materia:</label>
            </Grid>
            <Grid item xs={5} md={5}>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={assignmentDetail.clave_materia}
              />
              <input
                disabled
                className="filter-input"
                style={{ width: "300px" }}
                value={assignmentDetail.materia}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{ paddingLeft: 0, paddingRight: 0, marginLeft: "-3%" }}
            >
              <label style={{ float: "right" }}>Complementario:</label>
            </Grid>
            <Grid item xs={2} md={1}>
              <input
                disabled
                className="filter-input"
                value={assignmentDetail.complementario === true ? "SÍ" : "NO"}
                style={{ width: "150px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginLeft: "-2%" }}>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Periodo:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className="filter-input"
                style={{ width: "290px" }}
                value={periodo}
              />
            </Grid>
            {campusId === 12 && [
              <Grid item xs={1} style={{ marginLeft: "0.3%" }}>
                <label style={{ float: "right" }}>Horas:</label>
              </Grid>,
              <Grid item xs={5} md={5}>
                <input
                  disabled
                  className="filter-input"
                  style={{
                    width: "410px",
                    marginRight: "10px",
                  }}
                  value={assignmentDetail.horas}
                />
              </Grid>,
              <Grid
                item
                xs={1}
                style={{ paddingLeft: 0, paddingRight: 0, marginLeft: "-3%" }}
              >
                <label style={{ float: "right" }}>Semanas:</label>
              </Grid>,
              <Grid item xs={2} md={1}>
                <input
                  disabled
                  className="filter-input"
                  value={assignmentDetail.total_semanas}
                  style={{ width: "150px" }}
                />
              </Grid>,
            ]}
          </Grid>
        </Grid>

        <div
          style={{
            height: "65px",
            width: "80%",
            margin: "auto",
            marginTop: "0.5%",
          }}
        >
          <DataTable
            headers={headersTablaHorarioAsignar}
            data={dataHorarioAsignarFinal}
            loading={loadingHorarioAsignar}
            centrar={[1, 2, 3, 4, 5, 6, 7]}
            bordes
          />
        </div>

        <div
          style={{
            height: `${heightAsignar}px`,
            width: "90%",
            margin: "auto",
            marginTop: "30px",
          }}
        >
          <Grid
            container
            justifyContent="center"
            style={{ marginBottom: "20px" }}
          >
            <Grid xs={1} md={1} style={{ marginRight: "-0.5%" }}>
              <label>Total Grupos:</label>
            </Grid>
            <Grid xs={1} md={1}>
              <input
                disabled
                className="filter-input"
                style={{ width: "100px" }}
                value={totalGrupos}
              />
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{ marginLeft: "3%", marginRight: "-6.5%" }}
            >
              <label>Horas Asignadas:</label>
            </Grid>
            <Grid xs={1} md={1}>
              <input
                disabled
                className={"filterinputfunction"}
                style={{
                  width: "100px",
                  backgroundColor: consultarColor ? consultarColor : "#e5e5e5",
                  color: "white",
                }}
                value={horasAsignadas}
              />
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              style={{ marginLeft: "3%", marginRight: "-2%" }}
            >
              <label>Horas GSI:</label>
            </Grid>
            <Grid item xs={1} md={1}>
              <input
                disabled
                className="filter-input"
                style={{ width: "100px" }}
                value={horasGsi}
              />
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{ marginLeft: "3%", marginRight: "-8%" }}
            >
              <label>Porcentaje GSI:</label>
            </Grid>
            <Grid item xs={1} md={1}>
              <input
                disabled
                className="filter-input"
                style={{ width: "100px" }}
                value={porcentajeGsi}
              />
            </Grid>
          </Grid>
          <Tabs
            namesTabs={tabsNamesAsignar}
            containersTabs={containersTabsAsignar}
          />
        </div>
      </NewModal>

      <NewModal
        title="Desasignar Docente"
        open={desasignarModalIsOpen}
        handleClose={() => {
          setProfesorValue("");
          setMotivoBaja("");
          setFechaBaja("");
          setComentario("");
          setTotalGrupos("");
          setHorasAsignadas("");
          setHorasGsi("");
          setPorcentajeGsi("");
          setSemaforoIDperson("");
          setSemaforoIDassignment("");
          setConsultarColor("");
          setDataHorarioDesasignarFinal([]);
          setLoadingModalDesasignar(false);
          setDesasignarModalIsOpen(false);
        }}
        loading={loadingModalDesasignar}
        handleSubmit={handleSubmitDesasignar}
        height={830}
        maxWidth={1500}
      >
        <Grid style={{ padding: "50px" }}>
          <Grid container spacing={2} style={{ marginLeft: "-2%" }}>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Ciclo:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className="filter-input"
                style={{ width: "290px" }}
                value={assignmentDetail.ciclo}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "0.3%" }}>
              <label style={{ float: "right" }}>Escuela:</label>
            </Grid>
            <Grid item xs={5} md={5}>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={assignmentDetail.clave_escuela}
              />
              <input
                disabled
                className="filter-input"
                style={{ width: "300px" }}
                value={assignmentDetail.nombre_escuela}
              />
            </Grid>
            {campusId === 12 && [
              <Grid
                item
                xs={1}
                md={2}
                style={{ paddingLeft: 0, marginLeft: "-10.9%" }}
              >
                <label style={{ float: "right" }}>Módulo:</label>
              </Grid>,
              <Grid item xs={1} style={{ marginLeft: "-0.5%" }}>
                <input
                  disabled
                  className="filter-input"
                  value={assignmentDetail.modulo}
                  style={{ width: "150px" }}
                />
              </Grid>,
            ]}
          </Grid>
          <Grid container spacing={2} style={{ marginLeft: "-2%" }}>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Dirección:</label>
            </Grid>
            <Grid item xs={3} container>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={assignmentDetail.clave_direccion}
              />
              <input
                disabled
                className="filter-input"
                style={{ width: "180px" }}
                value={assignmentDetail.nombre_direccion}
              />
            </Grid>
            <Grid
              item
              xs={1}
              md={2}
              style={{ paddingLeft: 0, marginLeft: "-8%" }}
            >
              <label style={{ float: "right" }}>Tipo de Grupo:</label>
            </Grid>
            <Grid item xs={5} md={5}>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={assignmentDetail.clave_tipo_grupo}
              />
              <input
                disabled
                className="filter-input"
                style={{ width: "300px" }}
                value={assignmentDetail.tipo_grupo}
              />
            </Grid>
            <Grid
              item
              xs={1}
              md={2}
              style={{ paddingLeft: 0, marginLeft: "-10.9%" }}
            >
              <label style={{ float: "right" }}>Tipo Asistencia:</label>
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "-0.5%" }}>
              <input
                disabled
                className="filter-input"
                value={assignmentDetail.tipo_asistencia}
                style={{ width: "150px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginLeft: "-2%" }}>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Grupo:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className="filter-input"
                style={{ width: "290px" }}
                value={assignmentDetail.grupo}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "0.3%" }}>
              <label style={{ float: "right" }}>Materia:</label>
            </Grid>
            <Grid item xs={5} md={5}>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={assignmentDetail.clave_materia}
              />
              <input
                disabled
                className="filter-input"
                style={{ width: "300px" }}
                value={assignmentDetail.materia}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{ paddingLeft: 0, paddingRight: 0, marginLeft: "-3%" }}
            >
              <label style={{ float: "right" }}>Complementario:</label>
            </Grid>
            <Grid item xs={2} md={1}>
              <input
                disabled
                className="filter-input"
                value={assignmentDetail.complementario === true ? "SÍ" : "NO"}
                style={{ width: "150px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginLeft: "-2%" }}>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Periodo:</label>
            </Grid>
            <Grid item xs={3}>
              <input
                disabled
                className="filter-input"
                style={{ width: "290px" }}
                value={periodo}
              />
            </Grid>
            {campusId === 12 && [
              <Grid item xs={1} style={{ marginLeft: "0.3%" }}>
                <label style={{ float: "right" }}>Horas:</label>
              </Grid>,
              <Grid item xs={5} md={5}>
                <input
                  disabled
                  className="filter-input"
                  style={{
                    width: "410px",
                    marginRight: "10px",
                  }}
                  value={assignmentDetail.horas}
                />
              </Grid>,
              <Grid
                item
                xs={1}
                style={{ paddingLeft: 0, paddingRight: 0, marginLeft: "-3%" }}
              >
                <label style={{ float: "right" }}>Semanas:</label>
              </Grid>,
              <Grid item xs={2} md={1}>
                <input
                  disabled
                  className="filter-input"
                  value={assignmentDetail.total_semanas}
                  style={{ width: "150px" }}
                />
              </Grid>,
            ]}
          </Grid>
        </Grid>

        <div
          style={{
            height: "65px",
            width: "80%",
            margin: "auto",
            marginTop: "0.5%",
          }}
        >
          <DataTable
            headers={headersTablaHorarioDesasignar}
            data={dataHorarioDesasignarFinal}
            loading={loadingHorarioDesasignar}
            centrar={[1, 2, 3, 4, 5, 6, 7]}
            bordes
          />
        </div>

        <div
          style={{
            height: "484px",
            width: "90%",
            margin: "auto",
            marginTop: "30px",
          }}
        >
          <Grid
            container
            justifyContent="center"
            style={{ marginBottom: "20px" }}
          >
            <Grid xs={1} md={1} style={{ marginRight: "-0.5%" }}>
              <label>Total Grupos:</label>
            </Grid>
            <Grid xs={1} md={1}>
              <input
                disabled
                className="filter-input"
                style={{ width: "100px" }}
                value={totalGrupos}
              />
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{ marginLeft: "3%", marginRight: "-6.5%" }}
            >
              <label>Horas Asignadas:</label>
            </Grid>
            <Grid xs={1} md={1}>
              <input
                disabled
                className={"filterinputfunction"}
                style={{
                  width: "100px",
                  backgroundColor: consultarColor ? consultarColor : "#e5e5e5",
                  color: "white",
                }}
                value={horasAsignadas}
              />
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              style={{ marginLeft: "3%", marginRight: "-2%" }}
            >
              <label>Horas GSI:</label>
            </Grid>
            <Grid item xs={1} md={1}>
              <input
                disabled
                className="filter-input"
                style={{ width: "100px" }}
                value={horasGsi}
              />
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{ marginLeft: "3%", marginRight: "-8%" }}
            >
              <label>Porcentaje GSI:</label>
            </Grid>
            <Grid item xs={1} md={1}>
              <input
                disabled
                className="filter-input"
                style={{ width: "100px" }}
                value={porcentajeGsi}
              />
            </Grid>
          </Grid>

          <Tabs
            namesTabs={tabsNamesDesasignar}
            containersTabs={containersTabsDesasignar}
          />
        </div>
      </NewModal>
      <NewModal
        title={"Comentario"}
        open={modalComentarioIsOpen}
        handleClose={() => {
          setModalComentarioIsOpen(false);
        }}
        handleSubmit={handleComentarioGrupoDesasignar}
        loading={loadingModalComentario}
        style
        height={100}
        maxWidth={660}
        marginTop={"12%"}
      >
        <Grid container style={{ marginTop: "3.5%", marginLeft: "-1%" }}>
          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "0.5%" }}
          >
            <Grid item xs={12} md={7} style={{ textAlign: "center" }}>
              <label>
                Último grupo asignado del Profesor,
                <br />
                ¿Está seguro de aplicar la baja?
              </label>
            </Grid>
          </Grid>
        </Grid>
      </NewModal>
      <NewModal
        title={"Mantenimiento Fechas"}
        open={modalFechasMantIsOpen}
        handleClose={() => {
          setModalFechasMantIsOpen(false);
          setClaveProfesorMant("");
          setDataFechasMantenimiento([]);
        }}
        loading={loadingModalFechasMant}
        style
        height={400}
        maxWidth={860}
        marginTop={"1%"}
        noButtons
      >
        <Grid style={{ display: "flex", marginTop: "3%", marginLeft: "2%" }}>
          <Grid item xs={12} md={2}>
            <label>Clave Profesor:</label>
          </Grid>
          <Grid item xs={1} md={5} style={{ marginTop: "-1%" }}>
            <input
              disabled
              className="filter-input"
              style={{ width: "100%" }}
              value={claveProfesorMant}
            />
          </Grid>
        </Grid>
        <div
          style={{
            height: "calc(100% - 120px)",
            width: "85%",
            marginTop: "3%",
            marginLeft: "7%",
          }}
        >
          <DataTable
            headers={headersTableFechasMantenimiento}
            data={dataFechasMantenimiento}
            // loading={}
            centrar={[1, 2, 3, 4]}
            bordes
          />
        </div>
      </NewModal>
    </Contenedor>
  );
}

export default Asignaciones;
